<script setup>
import { Head, router } from '@inertiajs/vue3'
import { FormBuilder, Grid, useSchema } from '@codinglabsau/inertia-form-builder'
import { Container, Date, PrimaryButton, SecondaryButton } from '@codinglabsau/ui'
import ComboAxiosModelSearch from '@/Components/Forms/ComboAxiosModelSearch.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  ship: {
    type: [Object, null],
    required: false,
  },
})

const schema = useSchema({
  ship_id: {
    component: ComboAxiosModelSearch,
    label: 'Ship',
    value: props.ship?.id,
    placeholder: 'Search for a ship',
    props: {
      placeholder: 'Search for a ship',
      route: route('search.ships'),
    },
  },
  place_of_arrival_id: {
    component: ComboAxiosModelSearch,
    label: 'Place of Arrival',
    props: {
      placeholder: 'Search for a location',
      route: route('search.place-of-arrival'),
    },
  },
  date: {
    component: Grid,
    schema: {
      departure_date: {
        component: Date,
        props: {
          min: '1787-01-01',
          max: '1867-12-31',
        },
      },
      arrival_date: {
        component: Date,
        props: {
          min: '1787-01-01',
          max: '1867-12-31',
        },
      },
    },
  },
})

const submit = () => {
  schema.form.post(route('voyages.store'))
}

const cancel = () => {
  router.get(props.ship ? route('ships.show', props.ship) : route('voyages.index'))
}
</script>

<template>
  <Head title="Create Voyage" />

  <Container compact>
    <PageHeader title="Create Voyage" />

    <div class="rounded-b-lg bg-white p-3 pt-4">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions="{ form }">
            <div class="space-x-2">
              <SecondaryButton @click="cancel"> Cancel</SecondaryButton>
              <PrimaryButton :loading="form.processing" type="submit"> Save</PrimaryButton>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>
</template>
