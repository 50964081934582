<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3'
import { Button, Input, Label } from '@codinglabsau/gooey'
import GuestLayout from '@/Layouts/Guest.vue'
import Google from '@/Components/Icons/Google.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import FacebookIcon from '@/Components/FacebookIcon.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'

defineProps({
  canResetPassword: Boolean,
  facebookEnabled: Boolean,
  googleEnabled: Boolean,
  status: String,
})

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password'),
  })
}

defineOptions({ layout: GuestLayout })
</script>

<template>
  <div>
    <Head title="Log in" />

    <BreezeValidationErrors class="mb-4" />

    <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
      {{ status }}
    </div>

    <form class="space-y-4" @submit.prevent="submit">
      <div class="space-y-2">
        <div>
          <Label for="email"> Email </Label>
          <Input
            id="email"
            v-model="form.email"
            autocomplete="username"
            autofocus
            required
            type="email"
          />
        </div>

        <div>
          <Label for="password"> Password </Label>
          <Input
            id="password"
            v-model="form.password"
            autocomplete="current-password"
            required
            type="password"
          />
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex gap-2">
          <BreezeCheckbox v-model:checked="form.remember" name="remember" />
          <Label>Remember me</Label>
        </div>

        <Link
          v-if="canResetPassword"
          :href="route('password.request')"
          class="text-sm text-gray-600 underline hover:text-gray-900"
        >
          Forgot your password?
        </Link>
      </div>

      <div>
        <Button
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          class="w-full"
        >
          Log in
        </Button>
      </div>

      <div v-if="facebookEnabled || googleEnabled" class="flex w-full gap-4">
        <div class="my-4 h-px w-1/2 rounded bg-gray-200"></div>
        <span class="my-auto text-gray-500"> or </span>
        <div class="my-4 h-px w-1/2 rounded bg-gray-200"></div>
      </div>

      <div class="mx-auto flex flex-col space-y-2">
        <Button v-if="facebookEnabled" variant="outline" class="w-full" as-child>
          <a :href="route('oauth.facebook')" class="flex gap-1">
            <FacebookIcon class="my-auto size-4 text-blue-500" />
            Sign in with Facebook
          </a>
        </Button>

        <Button v-if="googleEnabled" variant="outline" class="w-full" as-child>
          <a :href="route('oauth.google')" class="flex gap-1">
            <Google class="my-auto size-4" />
            Sign in with Google
          </a>
        </Button>
      </div>
    </form>

    <div class="-mx-6 -my-4 mt-8 bg-stone-200">
      <div class="px-8 py-2 text-center text-xs text-gray-700">
        Don't have an account?
        <Link :href="route('register')" class="font-medium underline"> Register new account </Link>
      </div>
    </div>
  </div>
</template>
