<script setup>
import { Link, router } from '@inertiajs/vue3'
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Container, Dropdown, DropdownItem } from '@codinglabsau/ui'
import { user_image } from '@/helpers'

import Mascot from '@/Components/Mascot.vue'
import PageHeader from '@/Components/PageHeader.vue'

defineProps({
  user: Object,
  notifications: Object,
})

const markAllAsRead = () => {
  router.post(route('account.notifications.read-all'))
}

const deleteNotification = (notification) => {
  router.delete(
    route('account.notifications.delete', {
      notification: notification.id,
    }),
    {}
  )
}
</script>

<template>
  <Container>
    <PageHeader title="Notifications">
      <template #actions>
        <Dropdown>
          <template #text>
            <EllipsisVerticalIcon class="my-auto flex size-4 text-gray-400" />
          </template>

          <DropdownItem @click="markAllAsRead()"> Mark all as Read </DropdownItem>
        </Dropdown>
      </template>
    </PageHeader>

    <div class="bg-white py-4">
      <div v-if="notifications.length === 0" class="text-center text-gray-400">
        You have no new notifications.
      </div>

      <div v-else class="divide-y divide-stone-300">
        <Link
          v-for="notification in notifications"
          :key="notification.id"
          :href="route('account.notifications.read', { notification: notification.id })"
          method="post"
          class="w-full cursor-pointer text-left text-gray-700 hover:bg-stone-200"
        >
          <div class="p-4" :class="{ 'bg-stone-200': !notification.read_at }">
            <div class="flex grow items-start space-x-4">
              <Mascot
                v-if="notification.type === 'global_announcement'"
                class="h-16 w-auto rounded-full"
              />
              <img
                v-else
                :src="user_image(notification.image)"
                class="hidden h-16 w-auto rounded-full xs:block"
                :alt="notification.data.title"
              />

              <div class="grow space-y-1">
                <div class="relative">
                  <h3 class="pr-4 text-lg font-bold">
                    {{ notification.data.title }}
                  </h3>

                  <button
                    type="button"
                    class="absolute -right-4 -top-4 p-4"
                    @click.prevent="deleteNotification(notification)"
                  >
                    <XMarkIcon class="size-5 text-gray-400" />
                  </button>
                </div>

                <p>
                  {{ notification.data.body }}
                </p>

                <time class="block text-gray-400">
                  {{ notification.notified_at }}
                </time>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </Container>
</template>
