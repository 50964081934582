<script setup>
import { Container } from '@codinglabsau/ui'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import SupportersFaq from '@/Components/Supporters/SupportersFaq.vue'
import SubscriptionPrice from '@/Components/SubscriptionPrice.vue'

const features = [
  {
    name: 'Access to new Features',
    description:
      'As we release new features, supporters will be given early or exclusive access, such as starting conversations with other members.',
  },
  {
    name: 'Supporters Badge',
    description:
      'You will receive a special badge attached to your account displaying your support for Convict Records.',
  },
  {
    name: 'Helping support and grow Convict Records for all',
    description: 'Your support helps to underpin the ongoing success of Convict Records.',
  },
]
</script>

<template>
  <HeadMetaTags
    title="Supporters Club"
    description="Join the Convict Records Supporters Club! Get early access to new features, earn a supporter badge, and help grow this valuable resource for historians and ancestry enthusiasts."
    :canonical="route('supporters_club')"
    :url="route('supporters_club')"
  />
  <Container>
    <div class="bg-white p-4 md:p-8">
      <h1 class="md:text mt-2 font-serif text-3xl font-bold tracking-tight sm:text-4xl">
        Supporters Club
      </h1>

      <div class="mt-6 flex flex-col gap-6 lg:flex-row">
        <div class="flex-1 space-y-6">
          <p class="text-lg">
            We strive to deliver the best experience for all of our historians interested in the
            history of Australian Convicts.
          </p>
          <p class="text-lg">
            Your support helps us to continue to grow Convict Records as we work alongside our
            community to bring new features and improvements to the platform.
          </p>

          <h2 class="text-xl font-bold">Why become a supporter?</h2>

          <div v-for="feature in features" :key="feature.name" class="mt-4 space-y-8">
            <div>
              <h3 class="inline font-bold">{{ feature.name }}</h3>

              <div>
                {{ feature.description }}
              </div>
            </div>
          </div>

          <h2 class="mt-8 text-xl font-bold tracking-tight text-gray-900">
            Still unsure? No worries!
          </h2>

          <p class="mt-6 text-lg">
            You will still retain access to all the current free features of Convict Records, as
            these will never be locked behind Supporters Club. We are committed to our historians
            and their research, so these will remain as freely accessible as they have always been.
          </p>
        </div>
        <div class="flex-1">
          <SubscriptionPrice />
        </div>
      </div>

      <SupportersFaq class="mt-6" />
    </div>
  </Container>
</template>
