<script setup>
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import BaseIndexSearch from '@/Components/IndexSearch.vue'

const error = ref('')

const submit = (search, searchType) => {
  error.value = ''
  if (search && search.length >= 3) {
    if (searchType === 'fullname') {
      router.visit(route('convicts.index') + '?filter[q]=' + search)
    } else if (searchType === 'surname') {
      router.visit(route('convicts.index') + '?filter[surname]=' + search)
    } else if (searchType === 'firstname') {
      router.visit(route('convicts.index') + '?filter[firstname]=' + search)
    }
  } else {
    error.value = 'Search must have at least 3 characters.'
  }
}

const options = [
  { label: 'Full name', value: 'fullname' },
  { label: 'Surname', value: 'surname' },
  { label: 'Firstname', value: 'firstname' },
]
</script>

<template>
  <BaseIndexSearch
    :input-props="{ placeholder: 'Convict', error: error }"
    :options="options"
    @submit="submit"
  />
</template>
