<script setup>
import { ref } from 'vue'
import {
  Button,
  Input,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@codinglabsau/gooey'
import { ChevronDownIcon } from '@heroicons/vue/16/solid'

const props = defineProps({
  inputProps: {
    type: Object,
    default: () => ({
      type: 'text',
      placeholder: 'Search',
      error: '',
    }),
  },
  options: {
    type: Array,
    default: () => [],
  },
})

const emits = defineEmits(['submit'])

const search = ref()
const searchType = ref(props.options[0]?.value)

const submit = () => {
  emits('submit', search.value, searchType.value)
}
</script>

<template>
  <form class="h-full" @submit.prevent="submit">
    <div class="flex gap-1">
      <div class="relative mx-auto flex w-full max-w-2xl items-center md:min-w-80">
        <Input
          v-model="search"
          type="text"
          v-bind="inputProps"
          class="border bg-white p-2 text-gray-800 placeholder:text-gray-500 focus:border-muted focus:!ring-primary focus-visible:ring-primary"
        />
        <div v-if="options.length > 0" class="absolute right-2 flex items-center">
          <DropdownMenu>
            <DropdownMenuTrigger class="flex items-center text-sm">
              {{ options.find((option) => option.value === searchType)?.label }}
              <ChevronDownIcon class="size-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem v-for="option in options" :key="option.value">
                <button
                  type="button"
                  class="w-full text-left font-normal"
                  @click.prevent="searchType = option.value"
                >
                  {{ option.label }}
                </button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Button class="h-fit bg-secondary text-gray-800 hover:bg-slate-200" type="submit">
        Search
      </Button>
    </div>

    <div v-if="inputProps.error" class="mt-1">
      <p class="text-sm text-red-400">{{ inputProps.error }}</p>
    </div>
  </form>
</template>
