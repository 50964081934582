<script setup>
import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from 'chart.js'

const props = defineProps({
  data: Object,
  options: {
    type: Object,
    default: () => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: 0,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
          title: {
            display: false,
          },
        },
      }
    },
  },
})

ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
</script>

<template>
  <Pie :data="props.data" :options="options" />
</template>
