<script setup>
import { Container, PrimaryButton, SecondaryButton } from '@codinglabsau/ui'
import { router } from '@inertiajs/vue3'
import Table from '@/Components/Convicts/Table.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import Versions from '@/Components/Versions.vue'
import Versionable from '@/Components/Versionable.vue'

defineProps({
  crime: {
    type: Object,
    required: true,
  },
  convicts: {
    type: Object,
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  selectedVersion: {
    type: Number,
    required: true,
  },
})

const selectVersion = (version) => {
  router.reload({
    data: {
      version: version,
    },
  })
}
</script>

<template>
  <HeadMetaTags
    :title="crime.name"
    :description="`A Index of all of our recorded convicts who committed ${crime.name}.`"
    :canonical="route('crimes.show', crime.slug)"
    :url="route('crimes.show', crime.slug)"
  />

  <Container>
    <PageHeader :title="crime.name">
      <Versionable :versionable="crime" :version="selectedVersion" attribute="name" />

      <template #actions>
        <Versions :versionable="crime" :version="selectedVersion" @update:version="selectVersion" />

        <SecondaryButton v-if="canEdit" rel="nofollow" :href="route('crimes.merge.edit', crime)">
          Merge
        </SecondaryButton>

        <PrimaryButton v-if="canEdit" rel="nofollow" :href="route('crimes.edit', crime.slug)">
          Edit
        </PrimaryButton>
      </template>
    </PageHeader>

    <div class="rounded-b-lg bg-white p-3 pt-4">
      <Table :convicts="convicts" />
    </div>
  </Container>
</template>
