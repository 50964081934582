<script setup>
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Modal, PrimaryButton, Textarea, WarningAlert } from '@codinglabsau/ui'
import { ref, watch } from 'vue'

const props = defineProps({
  convict: {
    type: Object,
    required: true,
  },
  open: Boolean,
  hiddenButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const modal = ref(props.open || false)

const syncModal = (value) => {
  modal.value = value
  emit('update:open', value)
}

watch(() => props.open, syncModal)

const schema = useSchema({
  note: {
    component: Textarea,
  },
})

const closeModal = () => {
  syncModal(false)
}

const openModal = () => {
  syncModal(true)
}

const submit = () => {
  schema.form.post(route('convicts.notes.store', props.convict), {
    preserveScroll: true,
    onSuccess: () => {
      closeModal()
      schema.form.reset()
    },
  })
}
</script>

<template>
  <slot v-if="!hiddenButton" name="button">
    <PrimaryButton as="button" @click.prevent="openModal"> Add Note </PrimaryButton>
  </slot>

  <Modal :open="modal" @close="closeModal">
    <template #header>
      <div class="text-lg font-medium">Add a Note</div>
    </template>
    <WarningAlert v-if="$page.props.auth.user.level < 1">
      Notes will require moderation before they are visible
    </WarningAlert>
    <div>Enter the note for {{ convict.firstname }} here.</div>
    <form @submit.prevent="submit">
      <FormBuilder :schema="schema" />
    </form>
  </Modal>
</template>
