<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { DataTable } from '@codinglabsau/ui'
import { old_date, voyage_date_format } from '@/helpers'

const props = defineProps({
  convicts: {
    type: Object,
    required: true,
  },
  disableSearch: {
    type: Boolean,
    default: false,
  },
})

const search = ref(route().params.filter && route().params.filter.q ? route().params.filter.q : '')

const dataTableConfig = {
  columns: [
    {
      text: 'Convict',
      value: 'name',
      compact: true,
    },
    {
      text: 'Conviction',
      value: 'conviction',
    },
    {
      text: 'Transportation',
      value: 'transportation',
    },
  ],
  collection: props.convicts,
  search: search.value,
  enableSearch: !props.disableSearch,
}

const aliases = (person) => {
  return [person.alias1, person.alias2, person.alias3, person.alias4]
    .filter((alias) => alias !== null && alias !== '')
    .join(', ')
}

const life = (person) => {
  return [person.date_of_birth, person.date_of_death]
    .filter((date) => date !== null && date !== '0000-00-00')
    .map((date) => old_date(date, { year: 'numeric' }))
    .join(' - ')
}
</script>

<template>
  <div class="px-5 md:px-0">
    <DataTable v-bind="dataTableConfig" v-model:search="search">
      <template #item.name="{ item }">
        <div>
          <Link
            :href="route('convicts.show', item.slug)"
            class="font-bold text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            {{ item.firstname }} {{ item.surname }}
          </Link>
        </div>
        <div class="text-gray-500">
          {{ life(item) }}
        </div>
        <div class="max-w-xs truncate text-gray-500">
          {{ aliases(item) }}
        </div>
      </template>
      <template #item.conviction="{ item }">
        <div v-if="item.convicted_at" class="max-w-xs truncate">
          {{ item.convicted_at.name }}
        </div>
        <div v-if="item.crime">
          {{ item.crime.name }}
        </div>
        <div v-else class="text-gray-500">Not Recorded</div>
      </template>

      <template #item.transportation="{ item }">
        <div v-if="item.voyage && item.voyage.ship">
          <Link
            :href="route('ships.show', item.voyage.ship.url_name)"
            class="overflow-hidden font-bold text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            <div class="w-52 truncate sm:w-96">
              {{ item.voyage.ship.name }}
            </div>
          </Link>
        </div>
        <div v-if="item.voyage" class="max-w-xs truncate">
          {{
            voyage_date_format(item.voyage.departure_date) +
            ' - ' +
            (item.voyage.arrival_date ? voyage_date_format(item.voyage.arrival_date) : 'Unknown')
          }}
        </div>
        <div>
          <span :class="[item.voyage?.place_of_arrival?.name ? '' : 'text-gray-400']">
            {{ item.voyage?.place_of_arrival?.name ?? 'Unknown Port' }}
          </span>
        </div>
      </template>
      <template v-if="props.convicts.data.length === 0" #pagination>
        <div
          class="flex h-44 w-full items-center justify-center rounded-b bg-gray-200 px-5 text-xl text-gray-500"
        >
          No Convicts Found.
        </div>
      </template>
    </DataTable>
  </div>
</template>
