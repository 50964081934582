<script setup>
import { Link } from '@inertiajs/vue3'
import { BellIcon } from '@heroicons/vue/20/solid'
import UserAvatar from '@/Components/Users/UserAvatar.vue'

const props = defineProps({
  conversation: Object,
})

const isCurrent = route().current('messages.show', props.conversation.id)
</script>

<template>
  <Link
    :href="route('messages.show', conversation.id)"
    class="flex w-full items-center justify-between border-green-700 p-2 text-sm transition-all duration-300 ease-in-out hover:border-l-4 hover:bg-gray-50 hover:pl-3"
    :class="[isCurrent ? 'bg-gray-100' : 'bg-white']"
  >
    <div class="relative mr-1 shrink-0">
      <UserAvatar :user="conversation.recipient" small />
      <div v-if="conversation.new_message" class="absolute -left-1 -top-1 rounded-full bg-red-400">
        <BellIcon class="size-4 fill-white p-0.5" />
      </div>
    </div>
    <div class="w-full truncate">{{ conversation.recipient.name }}</div>
  </Link>
</template>
