<script setup>
const props = defineProps({
  targetId: String,
})
const scrollTo = () => {
  document.getElementById(props.targetId).scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
  <a
    class="cursor-pointer whitespace-nowrap no-underline transition-all duration-200 ease-in-out hover:border-l-4 hover:border-secondary-link hover:bg-gray-100 hover:pl-0.5"
    rel="nofollow"
    :href="'#' + props.targetId"
    @click.prevent="scrollTo()"
  >
    <slot />
  </a>
</template>
