<script setup>
import { usePage } from '@inertiajs/vue3'
import { CheckIcon } from '@heroicons/vue/20/solid'
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableCaption,
} from '@codinglabsau/gooey'

const userLevel = usePage().props.auth.user?.level

const headers = [
  {
    name: 'Tier',
    id: 'tier',
  },
  {
    name: 'Points Threshhold',
    id: 'points-threshhold',
  },
  {
    name: 'Make Convict Changes',
    id: 'make-convict-changes',
    level: 0,
  },
  {
    name: 'Add Notes',
    id: 'add-notes',
    level: 0,
  },
  {
    name: 'Contribute to a Ship',
    id: 'contribute-to-ship',
    level: 3,
  },
  {
    name: 'Contribute to a Voyage',
    id: 'contribute-to-voyage',
    level: 3,
  },
]

const tiers = [
  {
    name: 'Convict',
    id: 'convict',
    description: 'under 100 reputation',
    level: 0,
  },

  {
    name: 'Ticket Of Leave',
    id: 'ticket-of-leave',
    description: '100 to 499 reputation',
    level: 1,
  },
  {
    name: 'Free Settler',
    id: 'free-settler',
    description: '500 to 4999 reputation',
    level: 2,
  },
  {
    name: 'Governor',
    id: 'governor',
    description: '5000 or more.',
    level: 3,
  },
]

const actions = [
  {
    name: 'Add a Note to a Convict',
    id: 'add-note',
    value: 5,
  },
  {
    name: 'Contribute to a Convict Profile',
    id: 'contribute-convict',
    value: 10,
  },
  {
    name: 'Moderate a Convict Update',
    id: 'moderate-convict',
    value: 10,
  },
  {
    name: 'Moderate a Moderation',
    id: 'moderate-moderation',
    value: 10,
  },
  {
    name: 'Contribute to a Ship',
    id: 'contribute-ship',
    value: 20,
  },
]
</script>

<template>
  <div class="max-w-[85vw] space-y-6 sm:max-w-4xl">
    <div class="space-y-4">
      <div class="flex flex-col gap-y-1.5 text-left">
        <div class="font-semibold leading-none tracking-tight">Reputation Tiers</div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead v-for="title in headers" :key="title.id">
              {{ title.name }}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow
            v-for="tier in tiers"
            :key="tier.id"
            :class="userLevel === tier.level ? 'bg-primary-600/20 hover:bg-primary-500/10' : ''"
          >
            <TableCell class="font-medium">
              <div v-if="userLevel === tier.level" class="text-xs font-medium text-primary-700">
                Your tier
              </div>
              <div>
                {{ tier.name }}
              </div>
            </TableCell>
            <TableCell>
              {{ tier.description }}
            </TableCell>
            <TableCell v-for="title in headers.slice(2)" :key="title.id">
              <div v-if="tier.level >= title.level" class="flex">
                <CheckIcon class="size-4 text-primary-600" />
                <span v-if="tier.level === 0" class="text-muted-foreground"> * </span>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableCaption>
          * All actions will go through a moderation process, and changes will be made visible only
          after approval by a moderator.
        </TableCaption>
      </Table>
    </div>

    <div class="space-y-4">
      <div class="flex flex-col gap-y-1.5 text-left">
        <div class="font-semibold leading-none tracking-tight">Reputation Guide</div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead> Action </TableHead>
            <TableHead> Reputation point </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="action in actions" :key="action.id">
            <TableCell class="font-medium">
              {{ action.name }}
            </TableCell>
            <TableCell class="font-medium">
              {{ action.value }}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </div>
</template>
