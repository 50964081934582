<script setup>
import { computed } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import { Container, DataTable, PrimaryButton, SecondaryButton } from '@codinglabsau/ui'

import { user_image } from '@/helpers'

import MessageUserModal from '@/Components/Conversations/MessageUserModal.vue'
import SupporterBadge from '@/Components/Supporters/SupporterBadge.vue'
import LevelProgression from '@/Components/Users/LevelProgression.vue'
import SubSection from '@/Components/SubSection.vue'
import PageHeader from '@/Components/PageHeader.vue'
import ReadMoreText from '@/Components/ReadMoreText.vue'

const props = defineProps({
  user: Object,
  claims: Object,
  existingConversationID: Number,
  canSuspend: {
    type: Boolean,
    default: false,
  },
})

const claimsTableConfig = computed(() => ({
  columns: [
    {
      value: 'name',
      text: 'Convict Claimed',
      compact: true,
    },
    {
      value: 'description',
      text: 'Description',
    },
  ],
  collection: props.claims,
}))
</script>

<template>
  <Container>
    <PageHeader :title="props.user.name">
      <SupporterBadge v-if="props.user.supporter" class="size-8" />
      <template #actions>
        <div class="space-x-2">
          <SecondaryButton
            v-if="canSuspend && user.id !== $page.props.auth.user.id"
            @click="router.get(route('members.suspend.show', user.id))"
          >
            Suspend
          </SecondaryButton>
          <PrimaryButton
            v-if="existingConversationID || user.id === $page.props.auth.user.id"
            @click="router.get(route('messages.show', existingConversationID))"
          >
            View Messages
          </PrimaryButton>
          <MessageUserModal v-else :recipient="user" />
        </div>
      </template>
    </PageHeader>

    <div class="bg-white p-4 pt-5">
      <div class="flex w-full flex-col lg:flex-row">
        <div class="w-full lg:w-3/5">
          <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="border-t border-gray-100">
              <dl class="divide-y divide-gray-100">
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-gray-900">Avatar</dt>
                  <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <img
                      class="size-32 rounded-lg"
                      :src="user_image(user.image)"
                      :alt="user.name"
                    />
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-gray-900">Reputation</dt>
                  <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {{ parseInt(user.reputation).toLocaleString() }}
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-gray-900">Convict Contributions</dt>
                  <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {{ parseInt(user.convict_histories_count).toLocaleString() }}
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-gray-900">Convict Notes</dt>
                  <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {{ parseInt(user.convict_notes_count).toLocaleString() }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div class="z-0 mt-4 size-full rounded-lg shadow lg:ml-3 lg:mt-0 lg:w-2/5">
          <LevelProgression :user="user" />
        </div>
      </div>
      <SubSection v-if="claims.data.length > 0" title="Claims">
        <DataTable v-bind="claimsTableConfig">
          <template #item.name="{ item }">
            <Link
              class="text-primary visited:text-visited"
              :href="route('convicts.show', item.slug)"
            >
              {{ item.name }}
            </Link>
          </template>
          <template #item.description="{ item }">
            <ReadMoreText :text="item.description" line-clamp="3" />
          </template>
        </DataTable>
      </SubSection>
    </div>
  </Container>
</template>
