<script setup>
import { computed } from 'vue'
import moment from 'moment/moment'
import { Link, usePage } from '@inertiajs/vue3'
import { useSchema } from '@codinglabsau/inertia-form-builder'
import { PrimaryButton, SecondaryButton } from '@codinglabsau/ui'
import SubSection from '@/Components/SubSection.vue'

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits(['moderated'])

const user = usePage().props.auth.user

const schema = useSchema({})

const approve = () => {
  schema.form.post(route('convicts.notes.approve', { convictNote: props.note.id }), {
    onSuccess: () => {
      emits('moderated')
    },
  })
}

const reject = () => {
  schema.form.post(route('convicts.notes.reject', { convictNote: props.note.id }), {
    onSuccess: () => {
      emits('moderated')
    },
  })
}

const canModerate = computed(() => {
  const isModerator = props.note.moderator?.id === user?.id

  const isContributor = props.note.contributor.id === user?.id

  const alreadyModeratedAndUserIsLevel0 = props.note.moderator !== null && user?.level === 0

  return !(isModerator || isContributor || alreadyModeratedAndUserIsLevel0)
})
</script>

<template>
  <SubSection as="h2" :title="note.convict.name">
    <template #actions>
      <div v-if="canModerate" class="space-x-3">
        <SecondaryButton @click="reject()"><span class="text-xs">Reject</span></SecondaryButton>
        <PrimaryButton @click="approve()"><span class="text-xs">Approve</span></PrimaryButton>
      </div>
      <div v-else class="text-sm text-gray-500">
        <span v-if="!user"> You must be logged in to moderate. </span>
        <span v-else-if="note.moderated"> This note has already been moderated. </span>
        <span v-else-if="note.contributor.id === user?.id">
          You cannot moderate your own notes.
        </span>
        <span v-else-if="note.moderator?.id === user?.id">
          You have already moderated this note.
        </span>
        <span v-else-if="note.moderator?.id !== null && user?.level === 0">
          You will need a higher reputation to moderate this note.
        </span>
      </div>
    </template>
    <div class="space-y-2 bg-white">
      <div class="space-y-2 pt-4 text-sm">
        <div>
          Convict
          <Link
            :href="route('convicts.show', note.convict.slug)"
            class="text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            {{ note.convict.name }}
          </Link>
        </div>
        <div>
          Status <span class="text-gray-600">{{ note.status }}</span>
        </div>
        <div>
          Contributor
          <Link
            :href="route('members.show', note.contributor.id)"
            class="text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            {{ note.contributor.name }}
          </Link>
        </div>
        <div>
          Contribution Date
          <span class="text-gray-600">{{ moment(note.created_at).format('Do MMM YYYY') }}</span>
        </div>
      </div>
      <hr />
      <div class="prose whitespace-pre-line break-words">
        {{ note.note }}
      </div>
    </div>
  </SubSection>
</template>
