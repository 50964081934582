<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { Combobox } from '@codinglabsau/ui'
import { axiosRouteSearch } from '@/Composables/AxiosRouteSearch'

const props = defineProps({
  modelValue: {
    type: [Number, null],
    required: true,
  },
  route: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Search...',
  },
  error: String,
  id: String,
  description: String,
})

const emit = defineEmits(['update:modelValue'])

const selectedOption = ref()

watch(selectedOption, (value) => {
  emit('update:modelValue', value)
})

const { query, results } = axiosRouteSearch(props.route)

const computedOptions = computed(() => {
  if (!results.value.results) {
    return []
  }

  return results.value.results.map((result) => {
    return {
      id: result.id,
      name: result.label,
    }
  })
})

const onSearch = (keyword) => {
  if (keyword.length > 2) {
    query.value = keyword
  }
}

onMounted(() => {
  if (props.modelValue) {
    query.value = props.modelValue
  }
})

const initialWatch = watch(results, () => {
  selectedOption.value = computedOptions.value.find((option) => option.id === props.modelValue)?.id
  initialWatch()
})
</script>

<template>
  <Combobox
    v-model="selectedOption"
    :options="computedOptions"
    disable-filtering
    :clear-query-on-select="false"
    :placeholder="placeholder"
    nullable
    :error="error"
    :description="description"
    @search="onSearch"
  >
    <template #display-value-text="{ selected }">
      <div class="capitalize">
        {{ selected.name }}
      </div>
    </template>
    <template #option="{ option, active, selected }">
      <li
        :class="[
          'relative cursor-default select-none border-b px-3 py-2 ',
          selected ? 'bg-secondary-link text-white' : active ? 'bg-indigo-200' : '',
        ]"
      >
        <div class="flex-1 capitalize">{{ option.name }}</div>
      </li>
    </template>
  </Combobox>
</template>
