<script setup>
import { useDropZone, useFileDialog } from '@vueuse/core'
import { fileSizeForHumans } from '@/helpers'
import Vapor from 'laravel-vapor'
import { computed, ref, watch } from 'vue'
import { Button } from '@codinglabsau/gooey'
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { Textarea } from '@codinglabsau/ui'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  supportedMimeTypes: {
    type: Array,
    required: true,
  },
})

const files = defineModel('files', { type: Array })
const uploads = defineModel('uploads', { type: Array })

const uploadedFiles = ref({})

const dropzone = ref()

const uploadedFilesArray = computed(() =>
  Object.values(uploadedFiles.value)
    .filter((file) => file.uuid !== undefined)
    .map((file) => ({
      uuid: file.uuid,
      key: file.key,
      type: file.type,
      name: file.name,
      description: file.description,
    }))
)

watch(uploadedFilesArray, () => (uploads.value = uploadedFilesArray.value), { deep: true })

const onFilesInput = (filesToUpload) => {
  filesToUpload.forEach((file) => {
    uploadedFiles.value[file.name] = {
      raw: file,
      name: file.name,
      size_for_humans: fileSizeForHumans(file.size),
      progress: 0,
      type: file.type,
      preview: file.type.includes('image/') ? URL.createObjectURL(file) : null,
      description: '',
    }
  })

  Promise.all(
    filesToUpload.map((file) =>
      Vapor.store(uploadedFiles.value[file.name].raw, {
        visibility: 'public-read',
        progress: (progress) =>
          (uploadedFiles.value[file.name].progress = Math.round(progress * 100)),
      })
        .then((response) => {
          uploadedFiles.value[file.name].uuid = response.uuid
          uploadedFiles.value[file.name].key = response.key
          uploadedFiles.value[file.name].type = response.headers['Content-Type']
        })
        .catch(() => {
          uploadedFiles.value[file.name].error = 'Failed to upload file'
        })
    )
  )
}

function onDrop(files) {
  onFilesInput(files)
}

useDropZone(dropzone, {
  onDrop,
  dataTypes: props.supportedMimeTypes,
})

const { open: openImagesDialog, onChange: onImagesChange } = useFileDialog({
  accept: 'image/*',
  multiple: true,
})
onImagesChange((files) => onFilesInput(Array.from(files)))

const removeFile = (index) => {
  files.value.splice(index, 1)
}

const removeUploadedFile = (key) => {
  delete uploadedFiles.value[key]
  if (props.form.hasErrors) {
    props.form.clearErrors()
  }
}

const hasAnyFiles = computed(
  () => files.value.length > 0 || Object.keys(uploadedFiles.value).length > 0
)
</script>

<template>
  <div class="flex flex-col gap-4">
    <template v-if="hasAnyFiles">
      <div class="flex flex-col gap-3">
        <div v-for="(file, idx) in files" :key="file.id" class="rounded-lg border p-3">
          <div class="flex items-start gap-3">
            <img :src="file.thumb" :alt="file.id" class="size-32 rounded object-cover object-top" />
            <div class="-mt-1 flex-1">
              <Textarea
                v-model="file.description"
                placeholder="Add a description..."
                :error="form.errors[`files.${idx}.description`]"
                class="-mt-2"
              />
            </div>
            <Button variant="ghost" size="icon" @click="removeFile(idx)">
              <TrashIcon class="size-5 text-red-500" />
            </Button>
          </div>
        </div>

        <template v-if="Object.keys(uploadedFiles).length > 0">
          <div
            v-for="([key, file], idx) in Object.entries(uploadedFiles)"
            :key="file.filename"
            class="rounded-lg border p-3"
            :class="{ 'border-red-200': !!form.errors[`uploads.${idx}.key`] }"
          >
            <div class="flex items-start gap-3">
              <img
                v-if="file.preview"
                :src="file.preview"
                :alt="file.id"
                class="size-32 rounded object-cover object-top"
              />
              <div class="-mt-1 flex-1">
                <Textarea
                  v-model="file.description"
                  placeholder="Add a description..."
                  :error="form.errors[`uploads.${idx}.description`]"
                  class="-mt-2"
                />
              </div>
              <Button variant="ghost" size="icon" @click="removeUploadedFile(key)">
                <TrashIcon class="size-5 text-red-500" />
              </Button>
            </div>
            <p v-if="form.errors[`uploads.${idx}.key`]" class="mt-2 text-xs text-red-500">
              {{ form.errors[`uploads.${idx}.key`] }}
            </p>
          </div>
        </template>
      </div>

      <div
        ref="dropzone"
        class="flex w-full flex-col items-center justify-center border border-dashed py-6"
      >
        <span class="text-gray-400">Drop files here</span>
        <span class="text-sm text-gray-400">Or</span>
        <Button type="button" variant="outline" @click.prevent="openImagesDialog">
          <CloudArrowUpIcon class="mr-1 size-5" />
          Upload
        </Button>
      </div>
    </template>

    <div v-else class="text-center">
      <h3 class="mt-2 text-sm font-semibold text-gray-900">No Photos</h3>
      <p class="mt-1 text-sm text-gray-500">Upload a photo that is relevant to this convict.</p>
      <Button class="mt-2" type="button" variant="outline" @click.prevent="openImagesDialog">
        <CloudArrowUpIcon class="mr-1 size-5" />
        Upload
      </Button>
    </div>

    <p class="text-sm text-gray-500">
      Images must be one of the following formats:
      {{ supportedMimeTypes.map((t) => t.split('/')[1]).join(', ') }}
    </p>
  </div>
</template>
