<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0934 1.23691C19.5546 0.970662 20.1227 0.970662 20.5838 1.23691L36.0734 10.1805C36.5345 10.4467 36.8185 10.9387 36.8185 11.4711V29.3585C36.8185 29.891 36.5345 30.3829 36.0734 30.6492L20.5838 39.5928C20.1227 39.859 19.5546 39.859 19.0934 39.5928L3.60384 30.6492C3.14277 30.3829 2.85873 29.891 2.85873 29.3585V11.4711C2.85873 10.9387 3.14276 10.4467 3.60384 10.1805L19.0934 1.23691Z"
      fill="#0F1A17"
    />
    <path
      d="M19.0934 4.48838C19.5545 4.22213 20.1227 4.22213 20.5838 4.48838L33.2 11.7729C33.6611 12.0391 33.9451 12.5311 33.9451 13.0635V27.6328C33.9451 28.1652 33.6611 28.6572 33.2 28.9234L20.5838 36.2079C20.1227 36.4742 19.5545 36.4742 19.0934 36.2079L6.4772 28.9234C6.01612 28.6572 5.73209 28.1652 5.73209 27.6328V13.0635C5.73209 12.5311 6.01612 12.0391 6.4772 11.7729L19.0934 4.48838Z"
      fill="#0F1A17"
      stroke="#DD6B20"
      stroke-width="1.4903"
    />
    <path
      d="M20.4131 23.2146L23.0075 19.397L23.8253 20.7771L27.2657 22.8329L27.5749 28.1451L20.4131 32.6124L19.8491 25.3585L20.4131 23.2146Z"
      fill="#DD6B20"
    />
    <path
      d="M17.3642 21.0046V19.397L15.9512 20.1764V21.2483L12.1021 24.2692V28.0518L19.8491 32.5035L19.2644 25.2436L19.8491 23.0511L17.3642 21.0046Z"
      fill="#DD6B20"
    />
    <path
      d="M20.1134 20.7438C19.2989 20.7548 18.1602 20.2173 18.1602 20.2173V20.7438L20.1134 22.152L21.538 20.3688C21.538 20.3688 20.8896 20.7334 20.1134 20.7438Z"
      fill="#DD6B20"
    />
    <path
      d="M22.4424 17.6788L22.2854 18.4244C22.283 18.4358 22.2785 18.4459 22.2712 18.455C22.1541 18.6008 21.0783 19.9038 20.0398 19.8961C18.9726 19.8883 17.7702 18.4939 17.6273 18.3236C17.6179 18.3124 17.6123 18.2989 17.6106 18.2845L17.443 16.8579C17.4363 16.8009 17.371 16.7702 17.3208 16.798C17.2475 16.8386 17.1493 16.8858 17.0667 16.8999C16.5485 16.9885 16.4168 16.3352 16.2471 15.8429C16.076 15.3464 15.7339 14.6319 16.2471 14.4997C16.3263 14.4793 16.4358 14.4831 16.5119 14.4895C16.5587 14.4935 16.6007 14.4573 16.6007 14.4103V13.2237C16.6007 13.2213 16.6008 13.2213 16.6006 13.2189C16.5941 13.1442 16.3091 9.64362 19.8149 9.63576C23.2938 9.62795 23.3573 13.0903 23.3584 13.2166C23.3585 13.2206 23.3581 13.2221 23.3575 13.2261L23.1925 14.2694C23.1837 14.3248 23.236 14.3717 23.2912 14.3622C23.3599 14.3504 23.4457 14.3421 23.5111 14.3566C23.8894 14.4408 23.7284 14.9352 23.712 15.3183C23.6859 15.9287 23.1578 16.7133 23.037 16.733C22.96 16.7456 22.8537 16.7421 22.7868 16.7379C22.7501 16.7356 22.7161 16.7585 22.7056 16.7938L22.4424 17.6788Z"
      fill="#DD6B20"
      stroke="#DD6B20"
      stroke-width="0.306052"
    />
    <circle cx="20.7772" cy="25.5331" r="0.472055" fill="#0F1A17" />
    <circle cx="20.7772" cy="28.1879" r="0.472055" fill="#0F1A17" />
    <path
      d="M10.9976 16.1122C11.0821 15.8522 11.4499 15.8522 11.5344 16.1122L11.9608 17.4245C11.9986 17.5408 12.1069 17.6195 12.2292 17.6195H13.609C13.8824 17.6195 13.9961 17.9693 13.7749 18.13L12.6586 18.9411C12.5597 19.0129 12.5183 19.1403 12.5561 19.2566L12.9825 20.5689C13.067 20.8289 12.7694 21.0451 12.5482 20.8844L11.4319 20.0734C11.333 20.0015 11.199 20.0015 11.1001 20.0734L9.98382 20.8844C9.76265 21.0451 9.46507 20.8289 9.54955 20.5689L9.97594 19.2566C10.0137 19.1403 9.97233 19.0129 9.87342 18.9411L8.75711 18.13C8.53594 17.9693 8.6496 17.6195 8.92299 17.6195H10.3028C10.4251 17.6195 10.5334 17.5408 10.5712 17.4245L10.9976 16.1122Z"
      fill="#DD6B20"
    />
    <path
      d="M28.1422 16.1122C28.2266 15.8522 28.5945 15.8522 28.6789 16.1122L29.1053 17.4245C29.1431 17.5408 29.2515 17.6195 29.3737 17.6195H30.7536C31.027 17.6195 31.1406 17.9693 30.9195 18.13L29.8031 18.9411C29.7042 19.0129 29.6628 19.1403 29.7006 19.2566L30.127 20.5689C30.2115 20.8289 29.9139 21.0451 29.6927 20.8844L28.5764 20.0734C28.4775 20.0015 28.3436 20.0015 28.2447 20.0734L27.1284 20.8844C26.9072 21.0451 26.6096 20.8289 26.6941 20.5689L27.1205 19.2566C27.1583 19.1403 27.1169 19.0129 27.018 18.9411L25.9016 18.13C25.6805 17.9693 25.7941 17.6195 26.0675 17.6195H27.4474C27.5696 17.6195 27.678 17.5408 27.7158 17.4245L28.1422 16.1122Z"
      fill="#DD6B20"
    />
  </svg>
</template>
