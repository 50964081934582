<script setup>
import { defineAsyncComponent } from 'vue'
import { Link } from '@inertiajs/vue3'
import moment from 'moment'

const props = defineProps({
  article: Object,
  hideDate: {
    type: Boolean,
    default: false,
  },
})

const components = import.meta.glob('./Articles/**/*.vue')

const ArticleContent = defineAsyncComponent(() => {
  return new Promise((resolve) => {
    resolve(components[props.article.component]())
  })
})
</script>

<template>
  <article class="mx-auto w-full max-w-2xl rounded-lg bg-white p-4">
    <div class="prose">
      <h2>
        <Link :href="article.href" class="font-extrabold no-underline">
          {{ article.title }}
        </Link>
      </h2>

      <div v-if="!hideDate" class="-mt-5">
        <time class="whitespace-nowrap text-sm">
          {{ moment(article.created_at).format('DD MMM, YYYY') }}
        </time>
      </div>

      <component :is="ArticleContent" />
    </div>
  </article>
</template>
