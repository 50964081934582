import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import { MotionPlugin } from '@vueuse/motion'
import Layout from './Layouts/App.vue'

import '../css/app.css'

createInertiaApp({
  title: (title) => (title ? `${title} - Convict Records` : `Convict Records`),
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    const page = pages[`./Pages/${name}.vue`]
    page.default.layout = page.default.layout || Layout

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    if (import.meta.env.PROD) {
      // initialise Sentry
      Sentry.init({
        app,
        dsn: 'https://10c790fb6bb1d0c8e49468ca6d44bae1@o4508650476929024.ingest.us.sentry.io/4508650503929856',
        maxBreadcrumbs: 50,
        environment: 'production',
        tracesSampleRate: 0.3,
        integrations: [Sentry.browserTracingIntegration()],
        allowUrls: [/https:\/\/convictrecords.com.au/, /https:\/\/.*.cloudfront.net/],
      })

      // notify Google Analytics of page navigation
      router.on('navigate', () => {
        window.gtag('js', new Date())
        window.gtag('config', 'G-3JC7J36WNM')
      })
    }

    // use plugins and mount
    app.use(plugin).use(ZiggyVue, Ziggy).use(MotionPlugin).mount(el)
  },
  progress: {
    color: '#c2410c',
    delay: 0,
  },
})
