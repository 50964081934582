<script setup>
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Container, Hidden, Text } from '@codinglabsau/ui'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import DangerModal from '@/Components/DangerModal.vue'

const props = defineProps({
  crime: {
    type: Object,
    required: true,
  },
})

const schema = useSchema({
  name: {
    component: Text,
    label: 'Name of Crime',
    value: props.crime.name,
  },
  warning: {
    component: Hidden,
    label: 'Warning',
    value: props.crime.warning,
  },
})

const submit = () => {
  schema.form.put(route('crimes.update', { crime: props.crime.id }))
}

const destroy = () => {
  schema.form.delete(route('crimes.destroy', { crime: props.crime.id }))
}
</script>

<template>
  <HeadMetaTags
    :title="'Edit ' + crime.name"
    :description="'Edit crime ' + crime.name"
    :canonical="route('crimes.edit', crime.slug)"
    :url="route('crimes.edit', crime.slug)"
  />
  <Container compact>
    <PageHeader :title="'Edit ' + crime.name">
      <template #actions>
        <DangerModal
          v-if="crime.convicts_count === 0"
          confirm-text="Delete"
          :processing="schema.form.processing"
          @confirm="destroy"
        >
          <template #header>
            <span class="text-xl font-bold"> Delete Crime: {{ crime.name }} </span>
          </template>
          <template #body>
            This action cannot be undone, are you sure you want to delete this Crime?
          </template>
        </DangerModal>
      </template>
    </PageHeader>
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema" />
      </form>
    </div>
  </Container>
</template>
