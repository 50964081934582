<!-- component used to show a selection of results from a axios route request based on a id passed to the route -->
<script setup>
import axios from 'axios'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { Select } from '@codinglabsau/ui'

const props = defineProps({
  modelValue: {
    type: [Number, null],
    required: true,
  },
  searchId: {
    type: [Number, Function],
    required: true,
  },
  searchRoute: {
    type: String,
    required: true,
  },
  error: String,
  blank: {
    type: String,
    default: 'No Results Found',
  },
})

const emit = defineEmits(['update:modelValue'])

const options = ref([])

const selection = ref(null)

const showError = ref(false)

const resolvedSearchId = computed(() => {
  return typeof props.searchId === 'function' ? props.searchId() : props.searchId
})

const getOptions = async () => {
  await axios
    .get(props.searchRoute, { params: { searchId: resolvedSearchId.value } })
    .then((res) => {
      options.value = res.data.results
      showError.value = options.value.length === 0
    })
}

const computedOptions = computed(() => {
  return options.value.map((item) => {
    return {
      id: item.id,
      name: item.label,
    }
  })
})

watch(resolvedSearchId, (newVal) => {
  selection.value = null
  if (newVal) {
    getOptions()
  } else {
    options.value = []
  }
})

watch(selection, (newSelection) => {
  emit('update:modelValue', newSelection)
})

onMounted(() => {
  getOptions()
})

const initialWatch = watch(computedOptions, () => {
  if (props.modelValue === null) {
    return
  }
  selection.value = computedOptions.value.find((option) => option.id === props.modelValue)?.id
  initialWatch()
})

onUnmounted(() => {
  emit('update:modelValue', null)
})
</script>

<template>
  <Select
    v-model="selection"
    :error="error"
    class="capitalize"
    :class="{ 'border-red-400 text-red-600': showError }"
    :options="computedOptions"
    :blank="showError ? blank : ''"
    :disabled="showError"
  />
</template>
