<script setup>
import { ref, watch } from 'vue'
import { Number as UiNumber } from '@codinglabsau/ui'

const props = defineProps({
  filter: {
    default: null,
    type: Number,
  },
  min: {
    type: Number,
  },
  max: {
    type: Number,
  },
})

const emits = defineEmits(['filter'])

let numberRange = ref(props.filter)

watch(numberRange, (newVal) => {
  emits('filter', newVal)
})
</script>

<template>
  <UiNumber v-model="numberRange" :step="1" :min="min" :max="max" />
</template>
