<script setup>
import { computed } from 'vue'
import { Head, useForm } from '@inertiajs/vue3'
import { Button } from '@codinglabsau/gooey'
import GuestLayout from '@/Layouts/Guest.vue'

const props = defineProps({
  status: String,
})

const form = useForm({})

const submit = () => {
  form.post(route('verification.send'))
}

const verificationLinkSent = computed(() => props.status === 'verification-link-sent')

defineOptions({ layout: GuestLayout })
</script>

<template>
  <Head title="Email Verification" />

  <div class="mb-4 text-sm text-gray-600">
    Thanks for signing up! Before getting started, could you verify your email address by clicking
    on the link we just emailed to you? If you didn't receive the email, we will gladly send you
    another.
  </div>

  <div v-if="verificationLinkSent" class="mb-4 text-sm font-medium text-green-600">
    A new verification link has been sent to the email address you provided during registration.
  </div>

  <form @submit.prevent="submit">
    <div class="mt-4 flex items-center justify-between">
      <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Resend Verification Email
      </Button>
    </div>
  </form>
</template>
