<script setup>
import { computed, ref } from 'vue'
import { Container, SecondaryButton } from '@codinglabsau/ui'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import PageHeader from '@/Components/PageHeader.vue'
import SmallPagination from '@/Components/SmallPagination.vue'
import Show from '@/Components/Contributions/ConvictChange/Show.vue'
import NameFilter from '@/Components/Contributions/Filters/SearchFilter.vue'
import DropdownFilter from '@/Components/Contributions/Filters/DropdownFilter.vue'
import ConvictListItemNavigation from '@/Components/Contributions/ConvictListItemNavigation.vue'
import ReputationWarning from '@/Components/Reputation/ReputationWarning.vue'

const props = defineProps({
  convictChanges: {
    type: Object,
    required: true,
  },
})

const filters = ref(Object.keys(route().params.filter ?? {}).length)
const selectedChange = ref(props.convictChanges.data[0] || null)

const computedNavigation = computed(() => {
  return props.convictChanges.data.map((change) => {
    return {
      current: change.id === selectedChange.value?.id,
      ...change,
    }
  })
})

const sidebarOpen = ref(false)

const selectChange = (item) => {
  sidebarOpen.value = false
  selectedChange.value = item
}
</script>
<template>
  <Container class="lg:flex">
    <!--      mobile sidebar-->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex h-screen w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-4">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <aside class="flex h-dvh flex-col space-y-4 bg-white pt-2">
                <div class="space-y-3 px-4 py-2">
                  <NameFilter :collection="convictChanges" filter="convictSearch" />
                  <DropdownFilter
                    :options="['pending', 'pending approval', 'pending rejection']"
                    :collection="convictChanges"
                    filter="status"
                  />
                </div>
                <hr class="border-t border-gray-200" />

                <nav class="flex flex-1 grow flex-col overflow-hidden pl-4">
                  <ul role="list" class="space-y-2 overflow-y-auto">
                    <li v-if="computedNavigation.length > 0" class="space-y-2">
                      <div v-for="item in computedNavigation" :key="item.convict.name">
                        <ConvictListItemNavigation
                          :change="item"
                          :description="
                            item.changelog !== 'New Record'
                              ? item.changelog.join(', ')
                              : item.changelog
                          "
                          @click="selectChange(item)"
                        />
                      </div>
                    </li>
                  </ul>
                </nav>
                <SmallPagination :collection="props.convictChanges" class="px-5" />
              </aside>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="w-full">
      <PageHeader title="Convict Changes">
        <button
          type="button"
          class="-m-2.5 p-2.5 pr-5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </PageHeader>
      <div class="grow lg:flex">
        <!-- Static sidebar for desktop -->

        <div class="hidden shrink-0 grow-0 lg:inset-y-0 lg:flex lg:w-72 lg:flex-col">
          <aside class="flex grow flex-col space-y-4 border-r border-gray-200 bg-white">
            <div class="space-y-4 px-4 pt-4">
              <NameFilter :collection="convictChanges" filter="convictSearch" />
              <DropdownFilter
                :options="['pending', 'pending approval', 'pending rejection']"
                :collection="convictChanges"
                filter="status"
              />
            </div>
            <hr class="border-t border-gray-200" />
            <nav class="flex flex-1 grow flex-col pl-4">
              <ul role="list" class="flex flex-1 flex-col overflow-y-auto">
                <li v-if="computedNavigation.length > 0" class="space-y-2">
                  <div v-for="item in computedNavigation" :key="item.id">
                    <ConvictListItemNavigation
                      :change="item"
                      :description="
                        item.changelog !== 'New Record' ? item.changelog.join(', ') : item.changelog
                      "
                      @click="selectedChange = item"
                    />
                  </div>
                </li>
              </ul>
            </nav>
            <SmallPagination :collection="props.convictChanges" class="px-5" />
          </aside>
        </div>

        <main class="grow bg-white pt-3 lg:w-0 lg:px-8 lg:py-6">
          <ReputationWarning />

          <!-- Main area -->
          <Show
            v-if="selectedChange"
            :change="selectedChange"
            @moderated="selectedChange = convictChanges.data[0]"
          />
          <div
            v-else
            class="flex flex-col items-center justify-center space-y-1 rounded-lg border-2 border-dashed border-gray-300 py-12 text-center text-gray-300"
          >
            <span>
              {{
                !selectedChange && !filters ? 'There are no convict changes' : 'No results found'
              }}
            </span>
            <SecondaryButton v-if="filters" :href="route('convicts.changes.index')">
              Clear Filters
            </SecondaryButton>
          </div>
        </main>
      </div>
    </div>
  </Container>
</template>
