<script setup>
import { Container, Pagination, StackedList } from '@codinglabsau/ui'
import IndexHeader from '@/Components/Convicts/IndexHeader.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import IndexSearch from '@/Components/Convicts/IndexSearch.vue'

const props = defineProps({
  surname: {
    type: String,
    required: false,
    default: null,
  },
  names: {
    type: Object,
    required: true,
  },
  fullSurname: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const items = props.names.data.map((name) => ({
  name: props.fullSurname ? name.firstname + ' ' + name.surname : name.surname,
  href: props.fullSurname
    ? route('convicts.index.firstname', {
        surname: name.surname_url,
        firstname: name.firstname_url,
      })
    : route('convicts.index.surname', name.surname_url),
  icon: false,
  description: `${name.count} convicts`,
}))
</script>

<template>
  <HeadMetaTags
    title="Convicts By Name"
    description="A Index of all of our recorded convicts by Surname."
    :canonical="
      surname ? route('convicts.index.surname', { surname: surname }) : route('convicts.index')
    "
    :url="surname ? route('convicts.index.surname', { surname: surname }) : route('convicts.index')"
  />
  <Container>
    <IndexHeader />

    <div v-if="!fullSurname" class="flex h-full justify-end bg-white px-4 py-2">
      <IndexSearch class="w-full md:w-fit" />
    </div>

    <StackedList v-if="names.data.length > 0" :items="items" />
    <Pagination v-if="names.data.length > 0" :collection="names" />
    <div
      v-else
      class="flex h-56 w-full items-center justify-center rounded-b bg-gray-200 text-xl text-gray-500"
    >
      No results found
    </div>
  </Container>
</template>
