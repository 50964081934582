<script setup>
import { computed } from 'vue'
import { Link, router, usePage } from '@inertiajs/vue3'
import { Dropdown, DropdownItem } from '@codinglabsau/ui'
import {
  ArrowRightStartOnRectangleIcon,
  ChatBubbleLeftRightIcon,
  ShieldExclamationIcon,
  StarIcon,
  UserIcon,
} from '@heroicons/vue/24/outline'
import UserAvatar from '@/Components/Users/UserAvatar.vue'

const user = computed(() => usePage().props.auth.user)

const tabs = computed(() => {
  return [
    {
      name: 'Profile',
      icon: UserIcon,
      href: route('account.show'),
      current: route().current('account.show'),
      update: false,
    },
    {
      name: 'Supporters Club',
      icon: StarIcon,
      href: route('account.billing.show'),
      current: route().current('account.billing.*'),
      update: false,
    },
    {
      name: 'Messages',
      icon: ChatBubbleLeftRightIcon,
      href: route('messages.show'),
      current: route().current('messages.*'),
      update: user.value.unread_conversations.unread_messages_count > 0,
    },
    {
      name: 'Verify Email',
      icon: ShieldExclamationIcon,
      href: route('verification.notice'),
      update: true,
      hidden: user.value.verified,
    },
  ]
})
</script>

<template>
  <Dropdown v-if="user" text-classes="!border-none !shadow-none !px-0 !xs:px-3">
    <template #text>
      <span class="sr-only">Open user menu</span>
      <UserAvatar :user="user" small />
    </template>
    <div class="px-4 py-2">
      {{ user?.name ?? '' }}
    </div>
    <div
      v-for="tab in tabs"
      :key="tab.name"
      :class="{
        'border-l-4 border-l-secondary-link bg-gray-50 font-bold text-black': tab.current,
      }"
    >
      <DropdownItem
        v-if="!tab.hidden"
        :href="tab.href"
        rel="nofollow"
        class="transition-all duration-150 ease-in-out hover:border-l-2 hover:border-secondary-link"
      >
        <div class="relative flex border-none">
          <component :is="tab.icon" class="size-5 shrink-0" />
          <span class="ml-2">{{ tab.name }}</span>
          <div
            v-if="tab.update"
            class="full absolute -left-1 -top-1 size-3 rounded-full bg-red-400"
          />
        </div>
      </DropdownItem>
    </div>
    <DropdownItem
      rel="nofollow"
      class="transition-all duration-150 ease-in-out hover:border-l-2 hover:border-secondary-link"
      @click="router.post(route('logout'))"
    >
      <div class="flex">
        <ArrowRightStartOnRectangleIcon class="size-5 shrink-0" />
        <span class="ml-2">Sign Out</span>
      </div>
    </DropdownItem>
  </Dropdown>

  <div
    v-else
    class="flex items-center justify-center space-x-4 text-sm font-medium md:flex-row md:divide-x md:divide-y-0"
  >
    <Link
      rel="nofollow"
      :href="route('login')"
      class="border-transparent font-medium text-gray-500 hover:border-link-hover hover:text-link"
    >
      Login
    </Link>

    <Link
      rel="nofollow"
      :href="route('register')"
      class="border-transparent font-medium text-gray-500 hover:border-link-hover hover:text-link"
    >
      Register
    </Link>
  </div>
</template>
