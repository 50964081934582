<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import Reputation from '@/Components/Reputation/Reputation.vue'
import { useElementVisibility } from '@vueuse/core'
import LocalPageNavigationDropdown from '@/Components/LocalPageNavigationDropdown.vue'
import LocalPageNavigationItem from '@/Components/LocalPageNavigationItem.vue'
import FaqItem from '@/Components/FaqItem.vue'

const menu = [
  { name: 'Is Convict Records free?', id: 'free' },
  { name: 'What is the Supporter Club?', id: 'supporters-club' },
  { name: 'Where does the data come from?', id: 'data' },
  { name: 'How can I contribute?', id: 'how-to-contribute' },
  { name: 'Can I add a convict record?', id: 'add-convict' },
  { name: 'Can I edit a convict record?', id: 'edit-convict' },
  { name: 'What are convict notes?', id: 'convict-notes' },
  { name: 'How do I add a convict note?', id: 'add-convict-note' },
  { name: 'How do I edit a convict note?', id: 'edit-convict-note' },
  { name: "Why can't I see my notes?", id: 'why-notes-are-not-visible' },
  { name: 'What are the reputation levels?', id: 'reputation-levels' },
  { name: 'Why do you ask for my email address?', id: 'email' },
]

const heading = ref(null)
const headingIsVisible = useElementVisibility(heading)
</script>

<template>
  <HeadMetaTags
    title="FAQ"
    description="Have questions about Convict Records? Check out our FAQ for answers on searching convict records, contributing data, the Supporters Club, and much more."
    :canonical="route('faq')"
    :url="route('faq')"
  />

  <div class="flex justify-center gap-4 py-8 sm:py-10">
    <aside>
      <nav class="sticky top-0 hidden w-72 flex-col gap-2 sm:flex">
        <span class="prose prose-lg font-serif font-bold">FAQ</span>
        <div class="flex flex-col gap-2">
          <LocalPageNavigationItem v-for="item in menu" :key="item.id" :target-id="item.id">
            {{ item.name }}
          </LocalPageNavigationItem>
        </div>
      </nav>
    </aside>

    <article class="prose max-w-2xl grow">
      <nav
        v-show="!headingIsVisible"
        class="prose sticky top-0 z-10 -mx-2 my-4 border-b border-zinc-200 bg-gray-50/85 p-2 backdrop-blur sm:hidden"
      >
        <div class="flex items-center gap-x-2">
          <LocalPageNavigationDropdown>
            <span class="prose-lg mb-2 font-serif font-bold text-muted-foreground">
              Table of Contents
            </span>
            <LocalPageNavigationItem v-for="item in menu" :key="item.id" :target-id="item.id">
              {{ item.name }}
            </LocalPageNavigationItem>
          </LocalPageNavigationDropdown>

          <div>FAQ</div>
        </div>
      </nav>

      <h1 ref="heading">FAQ (Frequently Asked Questions)</h1>

      <p>
        This page is a collection of frequently asked questions about Convict Records. If you have a
        question or want to provide feedback, please use the chat icon in the bottom right of the
        screen to get in touch.
      </p>

      <FaqItem v-for="faq in menu" :key="faq.id" :faq="faq">
        <template v-if="faq.id === 'free'">
          <p>Yes! Convict Records was established in 2011, and has always been free to access.</p>
        </template>

        <template v-if="faq.id === 'supporters-club'">
          <p>
            The
            <Link :href="route('supporters_club')">Supporters Club</Link>
            is an optional membership program that allows you to support the team behind Convict
            Records and help us continue to grow and improve the platform.
          </p>
        </template>

        <template v-if="faq.id === 'data'">
          <p>
            convictrecords.com.au is based on the British Convict transportation register for
            convicts transported to Australia between 1787-1867.
          </p>

          <p>
            Additional information, including convicts who were missing from the original database
            have been added by volunteer contributors.
          </p>

          <p>
            You can find out more about the database on our
            <Link :href="route('resources')">resources</Link>
            page.
          </p>
        </template>

        <template v-if="faq.id === 'how-to-contribute'">
          <p>
            You can contribute to Convict Records by adding new convict records, editing existing
            records, and adding notes to convict profiles.
          </p>

          <p>
            Your contributions help to build a more comprehensive resource for researchers and
            family historians, and you will be credited for your work.
          </p>

          <p>
            If you have already registered, you can
            <Link :href="route('login')">login</Link>
            to your account start contributing straightaway. If you haven’t registered yet, no
            worries—just create an
            <Link :href="route('register')"> account</Link>
            on the site, and you’ll be ready to contribute in no time.
          </p>

          <p>
            Note that contributions are moderated, and we will remove anything that is offensive or
            unreasonable.
          </p>
        </template>

        <template v-if="faq.id === 'add-convict'">
          <p>
            To add a new convict record, click on the <strong>"Add Convict"</strong> button located
            in the top right corner of the
            <Link :href="route('convicts.index')">Convicts</Link>
            page. You will be redirected to a form where you can enter the convict's information.
            Once you have finished, click on the <strong>"Save"</strong> button to submit the
            record.
          </p>

          <p>
            Please note that depending on your
            <Link href="#reputation-levels">reputation level</Link>
            , you might need to wait for approval before your changes are published and visible on
            the site.
          </p>
        </template>

        <template v-if="faq.id === 'edit-convict'">
          <p>Any registered user can edit a convict record.</p>
          <p>
            To edit an existing convict record, visit the convict profile you want and clicking on
            the
            <strong>"Edit"</strong> button located at the top right of the page, next to the
            convict's name. You will be redirected to a form where you can add or edit the convict's
            information. Once you have finished, click on the <strong>"Save"</strong> button to
            submit your changes.
          </p>

          <p>
            Please note that depending on your
            <Link href="#reputation-levels">reputation level</Link>
            , you might need to wait for approval before your changes are published and visible on
            the site.
          </p>
        </template>

        <template v-if="faq.id === 'convict-notes'">
          <p>
            Convict notes are user-submitted contributions to a convict's record. Any registered
            user has the ability to add a note, helping to enrich the historical record with
            additional insights.
          </p>
          <p>
            You will also gain
            <Link href="#reputation-levels">reputation points</Link>
            for your contributions, which will help you to level up and unlock new features on the
            site.
          </p>
        </template>

        <template v-if="faq.id === 'add-convict-note'">
          <p>Any registered user can add a note to a convict record.</p>
          <p>
            To add a note to a convict record go to the
            <strong>"Convict Notes" section on the convict's profile</strong> page and
            <strong>click on the + icon</strong> next to the "Convict Notes" heading. You will be
            prompted to enter your note and depending on your
            <Link href="#reputation-levels">reputation level</Link>
            you might need to wait for approval before it is published and visible on the convict
            page.
          </p>
          <p>
            To view any pending convict contributions you can also visit
            <Link :href="route('convicts.notes.index')">Convict Notes</Link>
            page.
          </p>
        </template>

        <template v-if="faq.id === 'edit-convict-note'">
          <p>Any registered user can edit a note to a convict record.</p>

          <p>
            You can edit a note by clicking on the
            <strong>options menu indicated by the three dots next to your contribution</strong> that
            you want to edit.
          </p>

          <p>
            Please note that your
            <Link href="#reputation-levels">reputation level</Link>
            will determine if you can edit a note or if that note requires further moderation.
          </p>
        </template>

        <template v-if="faq.id === 'why-notes-are-not-visible'">
          <p>
            If you cannot see your note, it is likely that they are still pending approval or have
            been rejected. Pending notes are not visible on the convict page but they can be viewed
            on the
            <Link :href="route('convicts.notes.index')">Convict Notes</Link>
            page.
          </p>
        </template>

        <template v-if="faq.id === 'reputation-levels'">
          <p>
            Reputation levels are a way to reward users for their contributions to the site. As you
            contribute to Convict Records, you will earn reputation points that will help you level
            up and unlock new features.
          </p>
          <p>
            You can view your current reputation level by visiting your
            <Link :href="route('account.show')">account</Link>
            page.
          </p>

          <Reputation />
        </template>

        <template v-if="faq.id === 'email'">
          <p>Your email address is required to ensure we can contact you.</p>
          <ul>
            <li>
              We WILL NOT send you spam, or share your email address with any third parties, unless
              required by law
            </li>
            <li>
              We WILL send you important transactional emails about your account (for example, when
              a convict you have claimed is updated by someone else)
            </li>
          </ul>
        </template>
      </FaqItem>
    </article>
  </div>
</template>
