<script setup>
import { computed } from 'vue'

const props = defineProps({
  versionable: {
    type: Object,
    required: true,
  },
  version: {
    type: Number,
    required: true,
  },
  attribute: {
    type: String,
    required: true,
  },
})

const selectedVersion = computed(() => {
  if (props.version === props.versionable.current_version) {
    return null
  }

  return props.versionable.versions.find((version) => version.version === props.version)
})
</script>

<template>
  <template v-if="selectedVersion">
    <div class="space-x-2">
      <span class="bg-green-50 text-green-700">{{ selectedVersion.new_values[attribute] }}</span>
      <span class="bg-red-50 text-red-300 line-through">{{ versionable[attribute] }}</span>
    </div>
  </template>

  <span v-else>
    {{ versionable[attribute] }}
  </span>
</template>
