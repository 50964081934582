<script setup>
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import BaseIndexSearch from '@/Components/IndexSearch.vue'

const error = ref('')

const submit = (search) => {
  error.value = ''
  if (search && search > 1700) {
    router.visit(route('voyages.index') + '?filter[dates]=' + search)
  } else {
    error.value = 'Please enter a number between 1700 and 1900.'
  }
}
</script>

<template>
  <BaseIndexSearch
    :input-props="{
      placeholder: 'Year 1700-1900',
      error: error,
      type: 'number',
      min: '1700',
      max: '1900',
    }"
    @submit="submit"
  />
</template>
