import ShipPlaceholder from '@images/ship-placeholder.jpg'
import UserPlaceholder from '@images/user-placeholder.png'
import moment from 'moment'
import { usePage } from '@inertiajs/vue3'

export const old_date = (date, options = null) => {
  if (date === null || date.includes('0000')) {
    return ''
  }

  if (date.includes('0000') || date.includes('-00')) {
    return date.substring(0, 4)
  }

  options = options || { year: 'numeric', month: 'long', day: 'numeric' }

  return new Date(date).toLocaleDateString('en-GB', options)
}

export const voyage_date_format = (date) => {
  const newDate = moment(date.replace(/^-+/, '', 'YYYY-MM-DD', true)).format('D/M/YYYY')
  return newDate === 'Invalid date' ? 'Unknown' : newDate
}

export const ship_image = (url) => {
  if (url) {
    return url
  }

  return ShipPlaceholder
}

export const user_image = (url) => {
  if (url) {
    return url
  }
  return UserPlaceholder
}

export const flaggedAccess = (flag) => {
  return usePage().props.auth.user?.features.includes(flag)
}

export const fileSizeForHumans = (bytes) => {
  let kilobytes = bytes / 1024

  if (kilobytes > 1024) {
    return Math.round(kilobytes / 1024).toLocaleString('en-US') + ' MB'
  }

  return Math.round(kilobytes) + ' KB'
}

export const fileTypeIsAllowed = (file, allowedTypes) => {
  const fileType = file.type
  const allowed = allowedTypes.split(',').map((type) => type.trim())

  return allowed.some((allowedType) => {
    if (allowedType.endsWith('/*')) {
      // Match any subtype (e.g., image/*)
      const baseType = allowedType.split('/')[0]
      return fileType.startsWith(`${baseType}/`)
    } else {
      // Exact match (e.g., application/pdf)
      return fileType === allowedType
    }
  })
}
