<script setup>
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Modal, Textarea } from '@codinglabsau/ui'

const props = defineProps({
  convict: {
    type: Object,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const schema = useSchema({
  description: {
    component: Textarea,
  },
})

const closeModal = () => {
  emit('update:open', false)
}

const submit = () =>
  schema.form.post(route('convicts.claim', props.convict), {
    preserveScroll: true,
    onSuccess: () => {
      closeModal()
    },
  })
</script>

<template>
  <Modal :open="open" @close="closeModal">
    <template #header> {{ convict.name }}</template>
    <p>
      By claiming a convict, you'll have your name featured on their page. Feel free to make your
      claim for any reason – whether it's a familial connection or any other meaningful association.
      We just ask that you provide a brief description of your connection with the convict.
    </p>
    <form @submit.prevent="submit">
      <FormBuilder :schema="schema" />
    </form>
  </Modal>
</template>
