<script setup>
import { ref } from 'vue'
import {
  ChatBubbleLeftEllipsisIcon,
  PaperAirplaneIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/vue/24/outline'
import { SecondaryButton, Textarea } from '@codinglabsau/ui'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const openModal = ref(false)

const schema = useSchema({
  feedback: Textarea,
})

const submit = () => {
  if (schema.form.processing) {
    return
  }
  schema.form.post(route('feedback.submit'), {
    onSuccess: () => {
      openModal.value = false
      schema.form.reset()
    },
  })
}
</script>

<template>
  <TransitionRoot as="template" :show="openModal">
    <Dialog as="div" class="relative z-10" @close="openModal = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/10 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="sm:w-70 fixed mx-4 max-h-screen overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all sm:bottom-5 sm:right-5 sm:max-w-sm"
            >
              <div
                class="flex h-24 w-full items-center justify-center bg-blue-600 text-lg font-bold text-white"
              >
                <ChatBubbleLeftRightIcon class="mr-1 size-6" />
                Your feedback is important to us!
              </div>
              <div class="px-4 pb-4 pt-5">
                <div class="overflow-hidden">
                  <span class="prose">
                    Thank you for taking the time to provide feedback. We appreciate hearing from
                    you and will review your comments carefully.
                  </span>
                </div>
                <form @submit.prevent="submit">
                  <FormBuilder :schema="schema">
                    <template #actions>
                      <SecondaryButton
                        :loading="schema.form.processing"
                        type="submit"
                        class="rounded-full border-none bg-transparent shadow-none"
                        @click="submit"
                      >
                        <span v-if="schema.form.processing"> Sending... </span>
                        <PaperAirplaneIcon v-else class="size-6" />
                      </SecondaryButton>
                    </template>
                  </FormBuilder>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <div
    class="fixed bottom-1.5 right-1.5 z-50 size-10 rounded-full bg-emerald-900 p-2 text-white shadow-lg transition duration-300 hover:cursor-pointer hover:bg-emerald-800 sm:bottom-5 sm:right-5 sm:size-14 sm:hover:scale-125"
    @click="openModal = true"
  >
    <ChatBubbleLeftEllipsisIcon />
  </div>
</template>
