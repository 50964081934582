<script setup>
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import {
  Container,
  Pagination,
  PrimaryButton,
  SecondaryButton,
  StackedList,
  Text,
} from '@codinglabsau/ui'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  crimes: Object,
  canCreate: Boolean,
  q: String,
})

const items = props.crimes.data.map((crime) => ({
  name: crime.name,
  href: route('crimes.show', crime.slug),
  description: `${crime.convicts_count} convicts`,
  icon: false,
}))

const search = ref(props.q)

const submit = () => router.visit(route('crimes.index') + '?q=' + search.value)
</script>

<template>
  <HeadMetaTags
    title="Crimes"
    description="A Index of all of our recorded crimes committed by convicts."
    :canonical="route('crimes.index')"
    :url="route('crimes.index')"
  />
  <Container>
    <PageHeader title="Crimes">
      <template #actions>
        <form class="flex" @submit.prevent="submit">
          <Text v-model="search" placeholder="Crime name" />
          <SecondaryButton class="ml-1 mt-1" type="submit"> Search</SecondaryButton>
        </form>

        <PrimaryButton v-if="canCreate" rel="nofollow" :href="route('crimes.create')">
          Create Crime
        </PrimaryButton>
      </template>
    </PageHeader>

    <StackedList :items="items" />

    <Pagination :collection="crimes" />
  </Container>
</template>
