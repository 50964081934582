<script setup>
import { Container } from '@codinglabsau/ui'
import BecomeASupporterBanner from '@/Components/BecomeASupporterBanner.vue'
import PageHeader from '@/Components/PageHeader.vue'
import SupportersFaq from '@/Components/Supporters/SupportersFaq.vue'
import SubscriptionPrice from '@/Components/SubscriptionPrice.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  subscription: String,
  finishingDate: String,
})

const isSubscribed = props.user.supporter
</script>

<template>
  <BecomeASupporterBanner />
  <Container>
    <PageHeader title="Supporters Club" />
    <div class="space-y-6 bg-white p-4 md:p-8">
      <p v-show="isSubscribed">Thank you for your ongoing support!</p>
      <SubscriptionPrice :subscription="subscription">
        <template v-if="finishingDate" #end-date>
          <div class="text-center text-sm text-gray-500">
            Your subscription will end on {{ finishingDate }}
          </div>
        </template>
      </SubscriptionPrice>

      <SupportersFaq />
    </div>
  </Container>
</template>
