<script setup>
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Modal, PrimaryButton } from '@codinglabsau/ui'
import { ref, watch } from 'vue'

const props = defineProps({
  convictNote: Object,
  open: Boolean,
  hiddenButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const modal = ref(props.open || false)

const syncModal = (value) => {
  modal.value = value
  emit('update:open', value)
}

watch(() => props.open, syncModal)

const schema = useSchema({})

const closeModal = () => {
  syncModal(false)
}

const openModal = () => {
  syncModal(true)
}

const submit = () => {
  closeModal()
  schema.form.delete(route('convicts.notes.delete', props.convictNote), {
    preserveScroll: true,
  })
}
</script>

<template>
  <slot v-if="!hiddenButton" name="button">
    <PrimaryButton as="button" @click.prevent="openModal"> Delete</PrimaryButton>
  </slot>

  <Modal :open="modal" @close="closeModal">
    <template #header>
      <div class="text-lg font-medium">Delete Your Note?</div>
    </template>
    <p>Are you sure you want to delete this note? This cannot be undone</p>
    <p class="line-clamp-5 whitespace-pre-line break-words">{{ convictNote.note }}</p>
    <form @submit.prevent="submit">
      <FormBuilder :schema="schema">
        <template #actions>
          <PrimaryButton as="button" @click.prevent="submit"> Delete</PrimaryButton>
        </template>
      </FormBuilder>
    </form>
  </Modal>
</template>
