<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { DataTable } from '@codinglabsau/ui'
import NumberFilter from '@/Components/NumberFilter.vue'
import { ship_image, voyage_date_format } from '@/helpers'

const props = defineProps({
  voyages: {
    type: Object,
    required: true,
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
  hidePaginator: {
    type: Boolean,
    default: false,
  },
})

const search = ref(route().params.filter && route().params.filter.q ? route().params.filter.q : '')

let voyageTableConfig = {
  columns: [
    {
      text: 'Voyage',
      value: 'departure_date',
      sortable: props.sortable,
    },
    {
      text: 'Passengers',
      value: 'passenger_count',
    },
    {
      text: 'Average Sentence',
      value: 'average_sentence',
    },
    {
      text: 'Life Sentences',
      value: 'life_sentences',
    },
  ],
  collection: props.voyages,
  search: search.value,
  enableSearch: props.searchable,
  filters: props.searchable ? [{}] : [],
  customFilters: [
    {
      label: 'Filter By Year of Departure or Arrival',
      key: 'dates',
      component: NumberFilter,
      placeholder: 'Between 1787 and 1867',
    },
  ],
}
</script>

<template>
  <DataTable v-bind="voyageTableConfig" v-model:search="search">
    <template #item.departure_date="{ item }">
      <div class="flex h-24 min-w-64 items-start">
        <Link
          :href="route('ships.voyages.show', [item.ship_url, item.id])"
          class="shrink-0 text-link visited:text-visited hover:text-link-hover hover:underline"
        >
          <img
            :src="ship_image(item.ship.image)"
            :alt="item.ship.name"
            class="mr-4 size-24 rounded-sm"
          />
        </Link>

        <div>
          <Link
            :href="route('ships.voyages.show', [item.ship_url, item.id])"
            class="flex flex-col text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            <span>
              {{
                voyage_date_format(item.departure_date) +
                ' - ' +
                (item.arrival_date ? voyage_date_format(item.arrival_date) : 'Unknown')
              }}
            </span>

            <span>{{ item.ship.name }}</span>
          </Link>

          <span>{{ item.place_of_arrival ?? 'Unknown Port' }}</span>
        </div>
      </div>
    </template>

    <template #item.passenger_count="{ item }">
      <div>{{ parseInt(item.passenger_count).toLocaleString() }}</div>
    </template>

    <template #item.average_sentence="{ item }">
      <div>{{ parseInt(item.average_sentence).toLocaleString() }} Years</div>
    </template>

    <template #item.life_sentences="{ item }">
      <div>{{ parseInt(item.life_sentences).toLocaleString() }}</div>
    </template>

    <template v-if="props.hidePaginator" #pagination>
      <div class="h-1"></div>
    </template>
  </DataTable>
</template>
