<script setup>
import { Container, Text } from '@codinglabsau/ui'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'

const schema = useSchema({
  name: {
    component: Text,
    label: 'Name of Crime',
  },
})
</script>

<template>
  <HeadMetaTags
    title="Create Crime"
    description="Create A Convict Crime"
    :canonical="route('crimes.create')"
    :url="route('crimes.create')"
  />
  <Container compact>
    <PageHeader title="Create Crime" />
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <form @submit.prevent="schema.form.post(route('crimes.store'))">
        <FormBuilder :schema="schema" />
      </form>
    </div>
  </Container>
</template>
