<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  text: String,
  lineClamp: {
    type: [String, Number],
    default: 3,
  },
})

const textRef = ref(null)
const expanded = ref(false)

const isTruncated = computed(() => textRef.value?.scrollHeight > textRef.value?.clientHeight)
// line-clamp-1
// line-clamp-2
// line-clamp-3
// line-clamp-4
const lineClampClass = computed(() => (expanded.value ? '' : `line-clamp-${props.lineClamp}`))
</script>

<template>
  <div>
    <p ref="textRef" :class="lineClampClass" class="text-wrap">
      {{ text }}
      <span
        v-if="expanded"
        class="cursor-pointer text-nowrap text-blue-500 underline"
        @click="expanded = false"
      >
        Show Less
      </span>
    </p>

    <div
      v-if="isTruncated && !expanded"
      class="cursor-pointer text-right text-blue-500 underline"
      @click="expanded = true"
    >
      Read More
    </div>
  </div>
</template>
