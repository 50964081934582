<script setup>
import { Container, Heading } from '@codinglabsau/ui'
import PieChart from '@/Components/PieChart.vue'
import BarChart from '@/Components/BarChart.vue'
import FactsCards from '@/Components/FactsCards.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'

const props = defineProps({
  crimes: Object,
  occupations: Object,
  voyages: Object,
  convicts: Object,
})

const chartColours = [
  '#fbbf24',
  '#7f1d1d',
  '#ea580c',
  '#6d28d9',
  '#db2777',
  '#2563eb',
  '#15803d',
  '#713f12',
  '#f87171',
  '#4f46e5',
  '#c026d3',
  '#8b5cf6',
  '#db2777',
  '#a855f7',
  '#2dd4bf',
  '#f87171',
  '#60a5fa',
  '#34d399',
  '#f472b6',
  '#6b7280',
]

const genderChartConfig = {
  labels: ['Female', 'Unknown', 'Male'],
  datasets: [
    {
      label: 'Data One',
      backgroundColor: ['#fbbf24', '#a855f7', '#2dd4bf'],
      data: Object.values(props.convicts.genders),
    },
  ],
}

const statsConfig = [
  { name: 'Convicts Recorded', stat: props.convicts.convictsCount.toLocaleString() },
  {
    name: 'Coverage of estimated total convicts',
    stat: ((props.convicts.convictsCount / 160000) * 100).toFixed(0) + '%',
  },
  { name: 'Convicts Named John Smith', stat: props.convicts.johnSmithCount },
  { name: 'Life Sentences', stat: props.convicts.lifeSentences.toLocaleString() },
  { name: 'Total Years Sentenced', stat: parseInt(props.convicts.totalTerm).toLocaleString() },
  {
    name: 'Average Years Sentence',
    stat: (props.convicts.totalTerm / props.convicts.convictsCount).toFixed(0),
  },
  {
    name: 'Convicts departed in a single year',
    stat: parseInt(props.voyages.highestYearCount).toLocaleString(),
  },
]

const voyagesChartConfig = {
  labels: Object.keys(props.voyages.byYear),
  datasets: [
    {
      backgroundColor: chartColours,
      data: Object.values(props.voyages.byYear),
    },
  ],
}

const crimesChartConfig = {
  labels: Object.keys(props.crimes),
  datasets: [
    {
      backgroundColor: chartColours,
      data: Object.values(props.crimes),
    },
  ],
}

const occupationsChartConfig = {
  labels: Object.keys(props.occupations),
  datasets: [
    {
      backgroundColor: chartColours,
      data: Object.values(props.occupations),
    },
  ],
}
</script>

<template>
  <HeadMetaTags
    title="Facts"
    description="Explore fascinating facts about Convict Records! Discover key stats, data insights, and graphs showcasing the extensive data we've collected over the years."
    :canonical="route('facts')"
    :url="route('facts')"
  />

  <Container class="space-y-8">
    <Heading class="mb-8">
      Facts
      <template #actions>
        <div class="w-72 whitespace-pre-line text-sm">
          The information on this page is sourced from our public contributions.
        </div>
      </template>
    </Heading>
    <FactsCards :stats="statsConfig" />

    <Heading class="mb-8">Convict Genders</Heading>

    <div class="h-80">
      <PieChart :data="genderChartConfig" />
    </div>

    <Heading class="mb-8">Top 20 Convicts Convictions</Heading>

    <div class="h-[600px] sm:h-96">
      <PieChart :data="crimesChartConfig" />
    </div>
    <Heading class="mb-8">Top 20 Convicts Occupations</Heading>

    <div class="h-[600px] sm:h-96">
      <PieChart :data="occupationsChartConfig" />
    </div>
    <Heading class="mb-8">Voyages Departures by Year</Heading>

    <div class="h-96">
      <BarChart :data="voyagesChartConfig" />
    </div>
  </Container>
</template>
