<script setup>
import { ref } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import { Bars3BottomLeftIcon } from '@heroicons/vue/20/solid'
import { Container, PrimaryButton, Slideover, Textarea } from '@codinglabsau/ui'

import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import ConversationGroup from '@/Components/Conversations/ConversationGroup.vue'

import Message from '@/Components/Conversations/Message.vue'

import Mascot from '@/Components/Mascot.vue'
import UserAvatar from '@/Components/Users/UserAvatar.vue'
import SubscriptionPrice from '@/Components/SubscriptionPrice.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  conversations: Object,
  messages: Object,
  correspondence: Number,
})

const schema = useSchema({
  message: {
    label: '',
    component: Textarea,
    placeholder: 'Type your message here...',
  },
})

const isSupporter = usePage().props.auth.user.supporter

const submit = () => {
  schema.form
    .transform((data) => ({
      ...data,
      recipient_id: props.correspondence.id,
    }))
    .post(route('messages.store'), {
      preserveScroll: true,
      onSuccess: () => {
        schema.form.reset()
      },
    })
}

const conversationsSlideOver = ref(false)

const closeSlideOver = () => {
  conversationsSlideOver.value = false
}
</script>

<template>
  <Container class="mb-5">
    <PageHeader title="Chat" />

    <div class="flex grid-cols-6 bg-white">
      <div class="hidden w-40 flex-col sm:flex lg:w-56">
        <div v-if="conversations.length > 0" class="divide-y border-b">
          <div v-for="conversation in conversations" :key="conversation.id">
            <ConversationGroup :conversation="conversation" />
          </div>
        </div>
        <div v-else class="flex h-full items-center justify-center">
          <span>No Conversations.</span>
        </div>
      </div>
      <div class="w-full border sm:border-l-0">
        <div
          v-if="correspondence"
          class="flex w-full items-center border border-r-0 border-t-0 bg-white"
        >
          <div class="flex pl-3 sm:hidden">
            <Bars3BottomLeftIcon
              class="size-8 rounded-lg border p-1 text-gray-500"
              @click="conversationsSlideOver = true"
            />
          </div>
          <div class="flex w-full justify-center">
            <Link
              v-if="!correspondence.suspended_at"
              rel="nofollow"
              :href="route('members.show', correspondence.id)"
              class="flex size-fit items-center justify-center gap-4 py-2"
            >
              <UserAvatar :user="correspondence" small />
              <div>{{ correspondence.name }}</div>
            </Link>
            <div v-else class="flex size-fit items-center justify-center gap-4 py-2">
              Suspended Account
            </div>
          </div>
        </div>
        <div class="w-full overflow-hidden">
          <div
            class="flex w-full flex-col-reverse overflow-y-auto bg-gray-100 px-4 py-6 sm:px-6 lg:px-8"
            :class="{
              'h-96': isSupporter,
              'h-[600px] !flex-col': !isSupporter && !correspondence,
            }"
          >
            <!--              default message instructions-->
            <div v-if="!correspondence && isSupporter" class="relative mt-5 flex items-start gap-4">
              <div class="shrink-0">
                <Mascot class="h-14 w-auto" />
              </div>
              <div class="flex flex-col gap-1">
                <div class="flex items-center gap-2 text-xs font-bold text-gray-700">
                  Convict Records
                </div>
                <div
                  class="max-w-sm rounded-lg bg-white p-2 text-sm antialiased shadow md:max-w-md lg:max-w-lg"
                >
                  <div v-if="conversations.length < 1">
                    You have no conversations. You can start a new conversation from a users profile
                    page.
                  </div>
                  <div v-else-if="!correspondence">Select a Conversation to view messages.</div>
                </div>
              </div>
            </div>
            <div
              v-if="!correspondence && !isSupporter"
              class="relative mt-5 flex items-start gap-4"
            >
              <div class="shrink-0">
                <Mascot class="h-14 w-auto" />
              </div>
              <div class="flex flex-col gap-1">
                <div class="flex items-center gap-2 text-xs font-bold text-gray-700">
                  Convict Records
                </div>
                <div class="rounded-lg bg-white p-4 text-sm antialiased shadow">
                  <SubscriptionPrice>
                    <template #message>
                      <span v-if="conversations.length > 0">
                        Select a user to continue a conversation.
                      </span>
                      To start a new conversation, you will need to join the Supporters Club.
                    </template>
                  </SubscriptionPrice>
                </div>
              </div>
            </div>

            <div v-for="message in messages" :key="message.id">
              <Message in-conversation :message="message" />
            </div>
          </div>
          <div
            v-if="correspondence && !correspondence.suspended_at"
            class="w-full border-t bg-gray-100 pb-4"
          >
            <form @submit.prevent="submit">
              <FormBuilder class="!mt-0 !max-w-full !bg-gray-100 px-5 !pt-1" :schema="schema">
                <template #actions>
                  <PrimaryButton :loading="schema.form.processing" type="submit">
                    <span v-if="schema.form.processing">Sending...</span>
                    <span v-else>Send</span>
                  </PrimaryButton>
                </template>
              </FormBuilder>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Container>
  <Slideover align="left" :open="conversationsSlideOver" @close="closeSlideOver">
    <template #header> Conversations </template>
    <div>
      <div v-for="conversation in conversations" :key="conversation.id">
        <ConversationGroup :conversation="conversation" />
      </div>
    </div>
  </Slideover>
</template>
