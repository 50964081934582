<script setup>
import { Container, Image, Password, Text, Email, Checkbox } from '@codinglabsau/ui'

import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { user_image } from '@/helpers'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const schema = useSchema({
  image: {
    component: Image,
    props: {
      currentImage: user_image(props.user.image),
      description: 'The image should be jpg, or png',
    },
  },
  name: {
    component: Text,
    value: props.user.name,
  },
  email: {
    component: Email,
    value: props.user.email,
  },
  set_password: {
    component: Checkbox,
    label: '',
    value: false,
    props: {
      label: 'Set a new account password?',
    },
  },
  password: {
    component: Password,
    visible: (form) => {
      return form.set_password
    },
  },
  password_confirmation: {
    label: 'Confirm password',
    component: Password,
    visible: (form) => {
      return form.set_password
    },
  },
})

const submit = () => {
  schema.form.transform((data) => ({ ...data, _method: 'put' })).post(route('account.update'))
}
</script>

<template>
  <Container>
    <PageHeader title="Edit Profile" />
    <div class="bg-white pb-4 pt-1">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema" />
      </form>
    </div>
  </Container>
</template>
