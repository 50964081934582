<script setup>
import { Head, router } from '@inertiajs/vue3'
import {
  Container,
  Date,
  Number,
  PrimaryButton,
  SecondaryButton,
  Select,
  Text,
  Textarea,
  WarningAlert,
} from '@codinglabsau/ui'
import { FormBuilder, Grid, useSchema } from '@codinglabsau/inertia-form-builder'
import ComboAxiosModelSearch from '@/Components/Forms/ComboAxiosModelSearch.vue'
import PageHeader from '@/Components/PageHeader.vue'
import SelectAxiosModelResults from '@/Components/Forms/SelectAxiosModelResults.vue'

const props = defineProps({
  convict: {
    type: Object,
    required: true,
  },
})

const genderOptions = [
  { id: 'u', name: 'Unknown' },
  { id: 'm', name: 'Male' },
  { id: 'f', name: 'Female' },
]

const schema = useSchema({
  name: {
    component: Grid,
    schema: {
      firstname: {
        component: Text,
        value: props.convict.firstname,
      },
      surname: {
        component: Text,
        value: props.convict.surname,
      },
      gender: {
        component: Select,
        props: {
          options: genderOptions,
        },
        value: props.convict.gender,
      },
    },
  },
  alias1: {
    component: Text,
    value: props.convict.alias1,
  },
  alias2: {
    component: Text,
    value: props.convict.alias2,
  },
  alias3: {
    component: Text,
    value: props.convict.alias3,
  },
  alias4: {
    component: Text,
    value: props.convict.alias4,
  },
  dates: {
    component: Grid,
    schema: {
      date_of_birth: {
        component: Date,
        value: props.convict.date_of_birth,
        props: {
          min: '1700-01-01',
          max: '1876-12-31',
          description: 'Leave blank if unknown',
        },
      },
      date_of_death: {
        component: Date,
        value: props.convict.date_of_death,
        props: {
          min: '1780-01-01',
          max: '1930-12-31',
          description: 'Leave blank if unknown',
        },
      },
    },
  },
  occupation_id: {
    component: ComboAxiosModelSearch,
    value: props.convict.occupation_id,
    props: {
      route: route('search.occupations'),
    },
  },
  crime_id: {
    component: ComboAxiosModelSearch,
    value: props.convict.crime_id,
    props: {
      route: route('search.crimes'),
    },
  },
  conviction: {
    component: Grid,
    schema: {
      convicted_at_id: {
        component: ComboAxiosModelSearch,
        value: props.convict.convicted_at_id,
        props: {
          route: route('search.convicted-at'),
        },
      },
      term: {
        component: Number,
        value: props.convict.term,
        props: {
          description: 'Sentence in years',
        },
      },
    },
  },
  ship_id: {
    component: ComboAxiosModelSearch,
    value: props.convict.voyage?.ship_id,
    props: {
      route: route('search.ships'),
    },
  },
  voyage_id: {
    component: SelectAxiosModelResults,
    value: props.convict.voyage?.id,
    props: {
      searchId: () => schema.form.ship_id,
      searchRoute: route('search.voyages'),
      placeholder: 'Search for a Voyage',
      blank: 'No voyages found',
    },
    visible: () => schema.form.ship_id !== null,
  },
  source: {
    component: Textarea,
    value: props.convict.source,
    props: {
      description: 'Provide references for the information provided (required)',
    },
  },
  notes: {
    component: Textarea,
    props: {
      description: 'Add any additional notes for this convict',
    },
  },
})

const submit = () => schema.form.put(route('convicts.update', props.convict))
const cancel = () => router.get(route('convicts.show', props.convict.slug))
</script>

<template>
  <Head :title="'Update ' + convict.name" />

  <Container compact>
    <PageHeader :title="'Update ' + convict.name" />
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <WarningAlert v-if="$page.props.auth.user.level < 1">
        Changes will require moderation before they are visible
      </WarningAlert>

      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions="{ form }">
            <div class="flex justify-end space-x-2">
              <SecondaryButton @click="cancel"> Cancel</SecondaryButton>
              <PrimaryButton :loading="form.processing" type="submit"> Save</PrimaryButton>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>
</template>
