<script setup>
import { Container, PrimaryButton } from '@codinglabsau/ui'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import VoyageTable from '@/Components/Voyages/Table.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  voyages: Object,
})
</script>

<template>
  <HeadMetaTags
    title="Voyages"
    description="A Index of all of our recorded voyages that took convicts to Australia."
    :canonical="route('voyages.index')"
    :url="route('voyages.index')"
  />
  <Container>
    <PageHeader title="Voyages">
      <template #actions>
        <PrimaryButton rel="nofollow" :href="route('voyages.create')">Add Voyage</PrimaryButton>
      </template>
    </PageHeader>

    <div class="rounded-b-lg bg-white p-3 pt-8">
      <VoyageTable :voyages="props.voyages" />
    </div>
  </Container>
</template>
