<script setup>
import { ChatBubbleLeftRightIcon } from '@heroicons/vue/24/outline'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Link, usePage } from '@inertiajs/vue3'
import UserAvatar from '@/Components/Users/UserAvatar.vue'

const user = usePage().props.auth.user
</script>

<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="group inline-flex items-center rounded-md p-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-2"
    >
      <div class="relative">
        <ChatBubbleLeftRightIcon class="size-7 stroke-zinc-500 sm:size-8" />

        <div
          v-if="user.unread_conversations.unread_messages_count > 0"
          class="absolute -bottom-0 -right-1 h-4 w-auto rounded-full bg-red-500 px-1 text-xs"
        >
          {{
            user.unread_conversations.unread_messages_count > 9
              ? '+9'
              : user.unread_conversations.unread_messages_count
          }}
        </div>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PopoverPanel
        class="absolute z-10 mt-2.5 w-72 max-w-md translate-x-[-70%] sm:w-screen sm:-translate-x-3/4"
      >
        <div class="overflow-hidden rounded-lg bg-gray-50 p-1 shadow-lg ring-1 ring-black/5">
          <h2 class="prose-xs m-4 font-semibold">New Messages</h2>

          <div v-if="user.unread_conversations.unread_messages_count > 0">
            <div
              v-for="(conversation, index) in user.unread_conversations.conversations"
              :key="conversation.id"
              class="mx-4 my-2 cursor-pointer rounded-md"
            >
              <div class="flex items-center gap-x-5 p-1 transition hover:bg-gray-100">
                <Link
                  rel="nofollow"
                  :href="route('members.show', conversation.user)"
                  class="shrink-0"
                >
                  <UserAvatar :user="conversation.user" small />
                </Link>

                <Link
                  rel="nofollow"
                  :href="route('messages.show', conversation.id)"
                  class="flex w-full flex-row items-center justify-between"
                >
                  <div class="grow">{{ conversation.user.name }} sent a message</div>

                  <div class="ml-2 h-fit rounded-full bg-red-500 px-4 text-white">
                    {{ conversation.conversation_user.unread_messages_count }}
                  </div>
                </Link>
              </div>

              <div class="flex justify-center">
                <hr
                  v-if="index + 1 !== user.unread_conversations.conversations.length"
                  class="mx-1 mt-2 w-4/5"
                />
              </div>
            </div>
          </div>

          <div v-else class="p-4 text-center text-sm text-gray-500">
            <p>No New Messages</p>
          </div>

          <hr class="mx-1 mt-2 flex" />

          <Link
            rel="nofollow"
            :href="route('messages.show')"
            class="mx-auto flex justify-center p-4 text-sm font-medium text-primary transition hover:text-primary/80"
          >
            All Messages
          </Link>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
