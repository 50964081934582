<script setup>
import AnnouncementBackground from '@images/banner.webp'
import AnnouncementBadge from '@images/banner-badge.webp'

const announcementBackgroundImage = AnnouncementBackground
const announcementBadgeIcon = AnnouncementBadge
</script>

<template>
  <div class="relative flex w-full items-center justify-center bg-red-50 md:h-[200px]">
    <img
      :src="announcementBackgroundImage"
      alt="Become a supporter for Convict Records background image"
      class="absolute inset-0 size-full object-cover"
    />

    <div class="relative my-12 h-full md:w-4/5 lg:my-2">
      <div class="mx-auto h-full max-w-7xl gap-3 sm:flex">
        <div class="flex items-center justify-center md:w-full">
          <img
            :src="announcementBadgeIcon"
            alt="Supporter Badge Icon"
            class="h-24 w-auto xs:h-32 md:h-40"
          />
        </div>

        <div class="flex md:w-full">
          <div class="m-auto text-center font-serif">
            <span class="text-xl text-primary md:text-3xl xl:text-5xl">
              Become a Supporter
              <br />
              of Convict Records Today
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
