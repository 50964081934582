<script setup>
import moment from 'moment'
import { Link, useForm } from '@inertiajs/vue3'

import { Container, PrimaryButton } from '@codinglabsau/ui'

import PageHeader from '@/Components/PageHeader.vue'
import SubSection from '@/Components/SubSection.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const userDetails = [
  {
    label: 'Name',
    value: props.user.name,
    link: route('members.show', props.user.id),
  },
  {
    label: 'Reputation',
    value: parseInt(props.user.reputation).toLocaleString(),
  },
  {
    label: 'Suspended At',
    value: moment(props.user.suspended_at).format('D/MM/YYYY'),
    hidden: props.user.suspended_at === null,
  },
  {
    label: 'Joined',
    value: moment(props.user.created_at).format('D/MM/YYYY'),
  },
  {
    label: 'Last Active',
    value: moment(props.user.last_login).format('D/MM/YYYY'),
  },
]

const form = useForm({})

const submit = () => {
  if (props.user.suspended_at) {
    form.post(route('members.unsuspend', props.user.id))
  } else {
    form.post(route('members.suspend', props.user.id))
  }
}
</script>

<template>
  <Container>
    <PageHeader :title="`Suspend ${user.name}?`">
      <template #actions>
        <PrimaryButton class="!py-1" @click.prevent="submit">
          {{ props.user.suspended_at ? 'Unsuspend' : 'Suspend' }}
        </PrimaryButton>
      </template>
    </PageHeader>
    <section class="space-y-4 bg-white pt-4 md:flex md:space-x-4 md:space-y-0 md:divide-x-2 md:p-4">
      <aside class="space-y-4 px-4 sm:px-6">
        <div v-for="detail in userDetails" :key="detail.label" class="flex text-sm md:block">
          <div v-if="!detail.hidden">
            <div class="w-24">{{ detail.label }}</div>
            <component
              :is="detail.link ? Link : 'div'"
              :href="detail.link"
              class="w-full text-gray-500 md:max-w-48"
            >
              {{ detail.value }}
            </component>
          </div>
        </div>
      </aside>
      <article class="grow md:pl-4">
        <div class="px-4">
          <p>Suspending a user will have the following actions to their account;</p>
          <ul class="list-inside list-decimal">
            <li>The account will not be publicly visible.</li>
            <li>The account cannot accessed.</li>
            <li>All claims will be removed from convicts.</li>
            <li>All pending notes and changes will be removed from moderation.</li>
          </ul>
        </div>

        <SubSection v-if="user.claims.length > 0" title="Claims">
          <div class="space-y-2 pt-4">
            <div class="flex">
              <div class="w-40">Convict</div>
              <div class="w-full">Description</div>
            </div>
            <hr class="mt-2 flex w-full border-b-0 border-zinc-600" />
            <div v-for="claim in user.claims" :key="claim.id" class="text-sm">
              <div class="flex">
                <Link
                  class="w-40 text-link visited:text-visited hover:text-link-hover hover:underline"
                  :href="route('convicts.show', claim.convict.slug)"
                >
                  {{ claim.convict.firstname }} {{ claim.convict.surname }}
                </Link>
                <div class="w-full">
                  {{ claim.description }}
                </div>
              </div>
              <hr class="mt-2 flex h-2 w-full" />
            </div>
          </div>
        </SubSection>
        <SubSection v-if="user.convict_notes.length > 0" title="Notes">
          <div class="space-y-2 pt-4">
            <div class="flex">
              <div class="w-40">Convict</div>
              <div class="w-full">Description</div>
            </div>
            <hr class="mt-2 flex w-full border-b-0 border-zinc-600" />
            <div v-for="note in user.convict_notes" :key="note.id" class="text-sm">
              <div class="flex">
                <Link
                  class="w-40 text-link visited:text-visited hover:text-link-hover hover:underline"
                  :href="route('convicts.show', note.convict.slug)"
                >
                  {{ note.convict.firstname }} {{ note.convict.surname }}
                </Link>
                <div class="w-full whitespace-pre-line">
                  {{ note.note }}
                </div>
              </div>
              <hr class="mt-2 flex h-2 w-full" />
            </div>
          </div>
        </SubSection>
      </article>
    </section>
  </Container>
</template>
