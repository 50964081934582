<script setup>
import SubSection from '@/Components/SubSection.vue'

const faqConfig = [
  {
    question: 'Can I cancel my Membership?',
    answer:
      "Yes, you can cancel your membership at any time. You'll continue to have access to your membership benefits until the end of your current billing period.",
  },
  {
    question: 'How can I cancel my subscription?',
    answer:
      'You can cancel your membership using the billing portal accessed from the edit button above.',
  },
  {
    question: 'What does my membership payment go towards?',
    answer:
      'Your membership payment goes towards supporting the development and maintenance of the Convict Records.',
  },
]
</script>

<template>
  <SubSection title="Frequently asked questions">
    <p class="mt-6 max-w-2xl">
      Have a different question and can’t find the answer you’re looking for? Reach out to our
      support team by using the chat icon in the bottom right corner of the screen.
    </p>

    <div class="mt-10">
      <dl
        class="space-y-8 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10"
      >
        <div v-for="(faq, index) in faqConfig" :key="index">
          <dt class="text-base font-semibold text-gray-900">
            {{ faq.question }}
          </dt>
          <dd class="mt-2 whitespace-pre-line text-base">
            {{ faq.answer }}
          </dd>
        </div>
      </dl>
    </div>
  </SubSection>
</template>
