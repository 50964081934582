<script setup>
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Modal, PrimaryButton, Textarea } from '@codinglabsau/ui'
import { ref, watch } from 'vue'

const props = defineProps({
  convictNote: Object,
  open: Boolean,
  hiddenButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const modal = ref(props.open || false)

const schema = ref(null)

const syncModal = (value) => {
  emit('update:open', value)
  schema.value = setSchema()
  modal.value = value
}

watch(() => props.open, syncModal)

const setSchema = () =>
  useSchema({
    note: {
      component: Textarea,
      value: props.convictNote.note,
    },
  })

const closeModal = () => {
  syncModal(false)
}

const openModal = () => {
  syncModal(true)
}

const submit = () => {
  schema.value.form.put(route('convicts.notes.update', props.convictNote), {
    preserveScroll: true,
    onSuccess: () => {
      closeModal()
      schema.value.form.reset()
    },
  })
}
</script>

<template>
  <slot v-if="!hiddenButton" name="button">
    <PrimaryButton as="button" @click.prevent="openModal"> Edit</PrimaryButton>
  </slot>

  <Modal :open="modal" @close="closeModal">
    <template #header>
      <div class="text-lg font-medium">Edit Your Note</div>
    </template>
    <div class="line-clamp-3 text-sm">{{ convictNote.note }}</div>
    <form @submit.prevent="submit">
      <FormBuilder :schema="schema" />
    </form>
  </Modal>
</template>
