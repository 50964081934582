<script setup>
import { ref, watch } from 'vue'
import { Modal, PrimaryButton, SecondaryButton, Textarea } from '@codinglabsau/ui'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { usePage } from '@inertiajs/vue3'
import SubscriptionPrice from '@/Components/SubscriptionPrice.vue'

const props = defineProps({
  recipient: Object,
  open: {
    type: Boolean,
    default: false,
  },
  hiddenButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const modal = ref(props.open || false)

const schema = ref(null)

const syncModal = (value) => {
  emit('update:open', value)
  schema.value = setSchema()
  modal.value = value
}

watch(() => props.open, syncModal)

const setSchema = () =>
  useSchema({
    message: {
      component: Textarea,
    },
  })

const closeModal = () => {
  schema.value.form.reset()
  syncModal(false)
}

const openModal = () => {
  syncModal(true)
}

const submit = () => {
  schema.value.form
    .transform((data) => ({
      ...data,
      recipient_id: props.recipient.id,
    }))
    .post(route('messages.store'), {
      preserveScroll: true,
      onSuccess: () => {
        closeModal()
      },
    })
}
</script>

<template>
  <slot v-if="!hiddenButton" name="button">
    <PrimaryButton as="button" @click.prevent="openModal">Message</PrimaryButton>
  </slot>
  <Modal :open="modal" auto-width @close="closeModal">
    <template #header>
      <div class="text-lg font-medium">Message {{ recipient.name }}</div>
    </template>
    <div v-if="usePage().props.auth.user.supporter">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions>
            <div class="flex gap-2">
              <SecondaryButton @click="closeModal()">Cancel</SecondaryButton>
              <PrimaryButton :loading="schema.form.processing" type="submit">Send</PrimaryButton>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
    <div v-else class="max-w-2xl">
      <div class="mb-4 flex w-full justify-center text-lg leading-8 text-gray-600">
        You will need to subscribe as a supporter to start new conversations.
      </div>
      <SubscriptionPrice />
    </div>
  </Modal>
</template>
