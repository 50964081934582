<script setup>
import { Link } from '@inertiajs/vue3'
import { ArrowRightIcon } from 'lucide-vue-next'
import { Container } from '@codinglabsau/ui'
import { Button } from '@codinglabsau/gooey'
import Backdrop from '@images/backdrop.png'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import ContentTabs from '@/Components/ContentTabs.vue'
import ConvictSummarySection from '@/Components/Convicts/ConvictSummarySection.vue'
import ConvictsIndexSearch from '@/Components/Convicts/IndexSearch.vue'
import ShipsIndexSearch from '@/Components/Ships/IndexSearch.vue'
import VoyagesIndexSearch from '@/Components/Voyages/IndexSearch.vue'
import SupporterBadge from '@/Components/Supporters/SupporterBadge.vue'
import SubscriptionPrice from '@/Components/SubscriptionPrice.vue'

const props = defineProps({
  totalConvicts: {
    type: Number,
  },
  convictOfTheDay: {
    type: Object,
  },
})

const backgroundImage = Backdrop
</script>

<template>
  <HeadMetaTags
    title="Find your Convict Ancestors"
    description="Discover Australia's rich convict history with Convict Records, the most comprehensive database of transported convicts from 1787 to 1867. Explore detailed records, contribute to historical research, and access open data for family history and education."
  />
  <div class="bg-white">
    <div
      class="flex w-full flex-col items-center justify-center bg-[#0D221B] bg-cover bg-bottom md:h-[600px] lg:bg-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div
        class="mx-auto w-full max-w-7xl space-y-6 rounded-lg bg-black/20 px-8 py-6 backdrop-blur-sm md:p-8"
      >
        <h1 class="font-serif text-4xl leading-relaxed text-white md:text-5xl md:leading-normal">
          Find your <br />
          <span class="italic">Convict Ancestors.</span>
        </h1>

        <div class="min-h-[300px] rounded-lg">
          <ContentTabs :headers="['Convicts', 'Ships', 'Voyages']">
            <template #Convicts>
              <div class="mb-5 space-y-2 text-slate-300 md:text-lg">
                <p>
                  Convict Records is a comprehensive history of Australian convicts, enriched by
                  stories contributed by our community about their friends and family.
                </p>

                <p>
                  Search the
                  <Link :href="route('convicts.index')" class="italic underline decoration-muted">
                    convicts that were transported to Australia.
                  </Link>
                </p>
              </div>
              <ConvictsIndexSearch class="max-w-2xl" />
            </template>

            <template #Ships>
              <div class="mb-5 space-y-2 text-slate-300 md:text-lg">
                <p>
                  Search the
                  <Link :href="route('ships.index')" class="italic underline decoration-muted">
                    ships that transported convicts to Australia.
                  </Link>
                </p>
              </div>
              <ShipsIndexSearch class="max-w-2xl" />
            </template>

            <template #Voyages>
              <div class="mb-5 space-y-2 text-slate-300 md:text-lg">
                <p>
                  Search the
                  <Link :href="route('voyages.index')" class="italic underline decoration-muted">
                    voyages the convicts took.
                  </Link>
                </p>
              </div>

              <VoyagesIndexSearch class="max-w-2xl" />
            </template>
          </ContentTabs>
        </div>
      </div>
    </div>

    <div class="p-4">
      <Container>
        <div class="flex flex-col items-center justify-center gap-10 sm:flex-row">
          <div class="prose-lg flex w-full flex-col gap-10 font-sans text-gray-800 md:flex-row">
            <div class="flex-1">
              Discover the stories of convicts transported to Australia from 1787 to 1867. We have
              data from the British Convict Transportation Register, courtesy of the State Library
              of Queensland.
            </div>
            <div class="flex-1">
              Currently, with the contributions of our community, we have details on
              <span class="font-medium italic"
                >{{ parseInt(props.totalConvicts).toLocaleString() }}
              </span>
              out of an estimated <span class="font-medium italic">160,000</span> convicts. Explore
              convicts, ships, and voyages.
            </div>
          </div>
        </div>
      </Container>
    </div>

    <div class="bg-black/90 p-4 text-slate-300">
      <Container>
        <div class="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-8">
          <div class="flex-1 space-y-6">
            <h2 class="font-serif text-3xl font-medium text-white">Join our supporters club</h2>
            <p>
              Your continuous support helps us to continue to grow Convict Records as we work
              alongside our community to bring new features and improvements to the platform.
            </p>

            <p>You also get access to exclusive features and benefits, like:</p>

            <ul class="space-y-2">
              <li class="list-inside list-disc">Early access to new features</li>
              <li class="list-inside list-disc">Start conversations with other members</li>
              <li class="list-inside list-disc">
                Exclusive badge <SupporterBadge class="inline-block size-8" />
              </li>
            </ul>
          </div>
          <div class="w-full sm:flex-1 md:flex md:justify-center">
            <SubscriptionPrice variant="solid" dark :show-features="false" />
          </div>
        </div>
      </Container>
    </div>

    <div
      v-if="convictOfTheDay"
      v-motion-slide-visible-once-bottom
      :duration="900"
      class="mt-6 px-4"
    >
      <Container>
        <div class="flex items-center gap-2 font-serif font-medium">
          <span class="shrink-0 text-xl">Convict of the Day</span>
          <div class="h-[0.75px] w-full bg-black"></div>
        </div>

        <h2 class="mt-10 font-serif text-3xl font-medium italic text-black">
          <a :href="route('convicts.show', convictOfTheDay.slug)">
            {{ convictOfTheDay.name }}
          </a>
        </h2>
        <ConvictSummarySection :convict="convictOfTheDay" class="pb-5" />

        <div>
          <Button
            :as="Link"
            :href="route('convicts.show', convictOfTheDay.slug)"
            class="mt-4"
            variant="outline"
          >
            View Convict <ArrowRightIcon class="ml-2 size-4" />
          </Button>
        </div>
      </Container>
    </div>

    <div class="mt-4 bg-gradient-to-r from-stone-200/20 via-stone-50 to-stone-200/30 p-4">
      <Container class="rounded-lg">
        <div class="my-5 space-y-5 text-center">
          <h2 class="font-serif text-3xl font-medium text-black">Join our Community</h2>
          <div class="prose-lg text-gray-700">
            When you contribute information about convicts, you not only enrich our database but
            also boost your reputation. As your reputation grows, so do your benefits – like the
            ability to update ships and voyages, and the chance to review changes made by fellow
            contributors.
            <br />
            Ready to get started? Click below to begin contributing!
          </div>
          <div class="flex w-full justify-center">
            <Button
              :as="Link"
              variant="outline"
              :href="$page.props.auth.user ? route('convicts.create') : route('register')"
            >
              Get Started <ArrowRightIcon class="ml-2 size-4" />
            </Button>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
