<script setup>
import { Container, PrimaryButton, SecondaryButton } from '@codinglabsau/ui'
import { router } from '@inertiajs/vue3'
import Table from '@/Components/Convicts/Table.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import Versions from '@/Components/Versions.vue'
import Versionable from '@/Components/Versionable.vue'

defineProps({
  occupation: {
    type: Object,
    required: true,
  },
  convicts: {
    type: Object,
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  selectedVersion: {
    type: Number,
    required: true,
  },
})

const selectVersion = (version) => {
  router.reload({
    data: {
      version: version,
    },
  })
}
</script>

<template>
  <HeadMetaTags
    :title="occupation.name"
    :description="`A Index of all of our recorded convicts who worked as a ${occupation.occupation}.`"
    :canonical="route('occupations.show', occupation.slug)"
    :url="route('occupations.show', occupation.slug)"
  />

  <Container>
    <PageHeader :title="occupation.name">
      <Versionable :versionable="occupation" :version="selectedVersion" attribute="name" />

      <template #actions>
        <Versions
          :versionable="occupation"
          :version="selectedVersion"
          @update:version="selectVersion"
        />

        <SecondaryButton
          v-if="canEdit"
          rel="nofollow"
          :href="route('occupations.merge.edit', occupation)"
        >
          Merge
        </SecondaryButton>

        <PrimaryButton v-if="canEdit" rel="nofollow" :href="route('occupations.edit', occupation)">
          Edit
        </PrimaryButton>
      </template>
    </PageHeader>

    <div class="rounded-b-lg bg-white p-3 pt-4">
      <Table :convicts="convicts" />
    </div>
  </Container>
</template>
