<script setup>
import { ref } from 'vue'
import { DangerButton, Modal, SecondaryButton } from '@codinglabsau/ui'

defineProps({
  confirmText: {
    type: String,
    required: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['confirm'])
const open = ref(false)
</script>

<template>
  <DangerButton type="button" @click.prevent="open = true"> {{ confirmText }} </DangerButton>
  <Modal :open="open" @close="open = false">
    <template #header>
      <slot name="header" class="text-xl font-bold" />
    </template>
    <slot name="body" class="whitespace-pre-wrap" />
    <template #footer>
      <div class="flex w-full justify-end gap-3">
        <SecondaryButton type="button" @click.prevent="open = false"> Cancel </SecondaryButton>
        <DangerButton type="button" :loading="processing" @click="emits('confirm')">
          {{ confirmText }}
        </DangerButton>
      </div>
    </template>
  </Modal>
</template>
