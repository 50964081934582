<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { axiosRouteSearch } from '../Composables/AxiosRouteSearch'

const props = defineProps({
  route: String,
  heading: {
    type: String,
    default: 'Results',
  },
})

const open = ref(false)
const { query, results } = axiosRouteSearch(props.route)

const selection = defineModel({
  type: Object,
})

const select = (item) => {
  selection.value = item
  open.value = false
}
</script>

<template>
  <div class="relative w-full max-w-52">
    <div
      class="relative flex w-full items-center border-b text-gray-400"
      @click.prevent="open = true"
    >
      <div class="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
        <MagnifyingGlassIcon class="size-5" />
      </div>
      <input
        type="text"
        class="pointer-events-none block w-full border-none ps-10 text-sm text-gray-900 focus:ring-0"
        placeholder="search"
      />
    </div>
  </div>

  <div class="absolute">
    <TransitionRoot :show="open" as="template" appear @after-leave="query = ''">
      <Dialog as="div" class="relative z-30" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600/50 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="mx-auto max-w-xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all"
            >
              <div class="relative w-full">
                <div class="relative flex w-full items-center border-b text-gray-400">
                  <div
                    class="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3"
                  >
                    <MagnifyingGlassIcon class="size-4" />
                  </div>
                  <input
                    v-model="query"
                    type="text"
                    class="block w-full border-none py-3 ps-10 text-sm text-gray-900 focus:ring-0"
                    placeholder="search"
                  />
                  <button class="mr-3 flex items-center" type="button" @click="open = false">
                    <XMarkIcon class="size-4" />
                  </button>
                </div>
                <div class="w-full overflow-hidden rounded-b-md bg-white shadow-lg">
                  <div class="max-h-96 w-full overflow-y-auto">
                    <div class="space-y-1 p-4">
                      <span class="text-lg font-semibold">{{ heading }}</span>
                      <div
                        v-if="results?.results?.length > 0"
                        class="space-y-2 divide-y rounded bg-white text-lg capitalize"
                      >
                        <div v-for="item in results.results" :key="item.id">
                          <div class="cursor-pointer" @click="select(item)">
                            <slot name="row" :item="item">
                              <div class="pl-2 hover:bg-teal-300">
                                {{ item.label }}
                              </div>
                            </slot>
                          </div>
                        </div>
                      </div>
                      <div v-else class="text-sm text-gray-500">No Results</div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
