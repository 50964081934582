<script setup>
import { ref, watch } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { ChevronDoubleDownIcon, ChevronDoubleRightIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { Container, PrimaryButton, WarningAlert } from '@codinglabsau/ui'

import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import AxiosSearch from '@/Components/AxiosSearch.vue'

const props = defineProps({
  crime: {
    type: Object,
    required: true,
  },
})

const selectedCrime = ref(null)

const form = useForm({
  crime_id: props.crime.id,
  target_crime_id: null,
})

watch(selectedCrime, (value) => {
  form.target_crime_id = value ? value.id : null
})

const submit = () => {
  form.put(route('crimes.merge.update', { crime: props.crime.id }))
}
</script>

<template>
  <HeadMetaTags
    :title="'Merge ' + crime.name"
    description="Merging Crimes."
    :canonical="route('crimes.merge.edit', crime.slug)"
    :url="route('crimes.merge.edit', crime.slug)"
  />
  <Container>
    <PageHeader :title="'Merge ' + crime.name" />
    <div class="bg-white p-4">
      <div class="mx-auto max-w-2xl space-y-4">
        <div>
          Merging will move all Convicts from
          <span class="font-semibold capitalize">{{ crime.name }}</span>
          into
          <span :class="selectedCrime ? 'font-semibold capitalize' : ''">
            {{ selectedCrime ? selectedCrime.label : 'the New Crime' }} </span
          >.
        </div>
        <form class="space-y-2" @submit.prevent>
          <div
            class="flex flex-col items-center justify-center space-y-5 p-5 sm:flex-row sm:space-x-10 sm:space-y-0"
          >
            <div>
              <div class="rounded-lg border p-6">
                <div class="text-lg font-semibold capitalize">{{ crime.name }}</div>
                <div class="text-sm text-gray-500">
                  {{ crime.convicts_count }} Convicts Assigned
                </div>
              </div>
            </div>
            <ChevronDoubleRightIcon class="hidden size-8 sm:block" />
            <ChevronDoubleDownIcon class="size-8 sm:hidden" />
            <div>
              <AxiosSearch
                v-if="!selectedCrime"
                v-model="selectedCrime"
                :route="route('search.crimes')"
              >
                <template #row="{ item }">
                  <div class="flex items-center justify-between">
                    <div>{{ item.label }}</div>
                    <div class="text-sm text-gray-500">{{ item.convicts_count }} convicts</div>
                  </div>
                </template>
              </AxiosSearch>
              <div v-else>
                <div class="relative rounded-lg border p-6">
                  <XMarkIcon
                    class="absolute -right-1.5 -top-1.5 size-5 cursor-pointer rounded-lg border bg-white"
                    @click="selectedCrime = null"
                  />
                  <div class="text-lg font-semibold capitalize">{{ selectedCrime.label }}</div>
                  <div class="text-sm text-gray-500">
                    {{ selectedCrime.convicts_count }} Convicts Assigned
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedCrime">
            <div>
              <WarningAlert>
                <div v-if="selectedCrime.id === crime.id" class="text-right">
                  Cannot merge a Crime with itself.
                </div>
                <ul v-else class="list-disc pl-2">
                  <li>
                    {{ selectedCrime.convicts_count }} Convicts Crimes will be updated to
                    <span class="font-semibold capitalize">{{ selectedCrime.label }}.</span>
                  </li>
                  <li>
                    <span class="font-semibold capitalize">{{ crime.name }}</span> will be removed.
                  </li>
                  <li>This action cannot be undone.</li>
                </ul>
              </WarningAlert>
            </div>
          </div>
          <div v-if="selectedCrime && selectedCrime.id !== crime.id" class="flex justify-end">
            <PrimaryButton :loading="form.processing" @click="submit"> Confirm Merge</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  </Container>
</template>
