<script setup>
import HeadMetaTags from '@/Components/HeadMetaTags.vue'

const elapsed = new Date().getFullYear() - 2011
</script>

<template>
  <HeadMetaTags
    title="About Convict Records"
    description="Learn about Convict Records, the most comprehensive database of transported convicts to Australia (1787-1867). Discover our mission, history, and ongoing contributions to historical research."
    :canonical="route('about')"
    :url="route('about')"
  />

  <article class="prose mx-auto px-4 py-20 sm:px-6 lg:px-8">
    <h1>About Convict Records</h1>

    <p>Welcome aboard Convict Records!</p>

    <p>
      Convict Records aims to be the most comprehensive reference of convicts transported to
      Australia, between 1787 to 1867.
    </p>

    <h2>A trip down (relatively recent) memory lane</h2>
    <p>
      Waaaaaaaaay back in 2011, the State Library of Queensland combined numerous State-based
      convict databases to produce the British Convict Transportation Registers.
    </p>

    <p>
      Soon afterwards, software developer Steve Thomas worked the data into a web application, and
      built tools allowing for public contribution.
    </p>

    <p>
      Later on, Convict Records moved under the stewardship of Steve's company;
      <a href="https://codinglabs.com.au" target="_blank">Coding Labs</a> - a software development
      agency based on the Gold Coast, Queensland.
    </p>

    <p>
      In the last {{ elapsed }} and a bit years,
      <strong>over 130,000 contributions have been made</strong> - annotating and tidying up
      existing records - and <strong>over 19,000 previously missing convicts</strong> have been
      added to the database.
      <strong>If you are reading this, and you contributed, thank you!</strong>
    </p>

    <h2>Our commitment</h2>

    <h3>The best resource of its kind</h3>

    <p>
      Convict Records has been the resource of choice for family researchers and educational
      institutions since 2011 and we continue to build on this success with an ongoing roadmap of
      improvements for this one-of-a-kind service.
    </p>

    <h3>Open data</h3>
    <p>
      When it comes to the historical data powering Convict Records (convict data that is) we
      strongly believe that this data should be more easily available and remain in the public
      domain.
    </p>

    <h3>Protecting your private information</h3>
    <p>
      Your private data (such as your email address) is kept private, and we will never share your
      private details without your consent.
    </p>

    <h2>Get in touch</h2>
    <p>
      We'd love to hear from our members about the features they want to see in Convict Records, as
      well as pointing things you like or don't like.
    </p>

    <p>Click on the feedback icon in the bottom right of the screen.</p>
  </article>
</template>
