<script setup>
import { Text } from '@codinglabsau/ui'
import { ref, watch } from 'vue'

const props = defineProps({
  // eslint-disable-next-line vue/require-prop-types
  filter: {
    default: null,
  },
  id: String,
  description: String,
})

const emit = defineEmits(['filter'])

const searchInput = ref()

searchInput.value = props.filter ? props.filter : ''

watch(
  searchInput,
  () => {
    emit('filter', searchInput.value)
  },
  { deep: true }
)
</script>

<template>
  <Text v-model="searchInput" placeholder="Search" class="w-full" />
</template>
