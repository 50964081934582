import { watchDebounced } from '@vueuse/core'
import axios from 'axios'
import { ref } from 'vue'

export function axiosRouteSearch(searchRoute) {
  const query = ref<string>(null)
  const results = ref({ results: [] })
  watchDebounced(
    query,
    () => {
      if (!query.value) {
        results.value = { results: [] } // Reset results if query is empty
        return
      }

      axios.get(searchRoute, { params: { q: query.value } }).then((res) => {
        results.value = res.data
      })
    },
    { debounce: 500, maxWait: 1000 }
  )

  return {
    query,
    results,
  }
}
