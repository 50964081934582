<script setup>
import { Link } from '@inertiajs/vue3'
import moment from 'moment'

const props = defineProps({
  convict: Object,
  loggedInUser: Object,
  claim: Boolean,
})

const aliasnames = () => {
  const names = []

  if (props.convict.alias1) {
    names.push(props.convict.alias1)
  }

  if (props.convict.alias2) {
    names.push(props.convict.alias2)
  }

  if (props.convict.alias3) {
    names.push(props.convict.alias3)
  }

  if (props.convict.alias4) {
    names.push(props.convict.alias4)
  }

  return names.join(', ')
}

const age = () => {
  if (!props.convict.date_of_birth || !props.convict.date_of_death) {
    return 'Unknown'
  }
  return moment(props.convict.date_of_death).diff(props.convict.date_of_birth, 'years') + ' years'
}
</script>

<template>
  <div>
    <div class="my-5">
      {{ convict.description ?? 'Not provided' }}
    </div>

    <div class="mt-16 h-24 text-sm">
      <div class="relative h-0.5 bg-zinc-400">
        <div class="absolute left-0 size-5 -translate-y-1/2 rounded-full bg-zinc-300">
          <div class="absolute left-0 -translate-y-full text-left">
            <div class="text-zinc-400">Born</div>
            <div class="w-20 text-zinc-500">
              {{
                convict.date_of_birth ? moment(convict.date_of_birth).format('MMM YYYY') : 'Unknown'
              }}
            </div>
          </div>
        </div>

        <div class="absolute left-1/4">
          <div class="absolute -left-2 size-4 -translate-y-1/2 rounded-full bg-zinc-300">
            <div class="absolute -left-6 top-6 h-full text-center">
              <span class="text-zinc-400 md:w-56">
                Conviction
                <span class="text-zinc-500">
                  {{ convict.crime ? convict.crime.name : 'Unknown' }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="absolute left-1/2">
          <div class="absolute -left-2 size-4 -translate-y-1/2 rounded-full bg-zinc-300">
            <div class="absolute -left-6 -translate-y-full text-center">
              <span class="text-zinc-400"> Departure </span>
              <span class="text-zinc-500">
                {{
                  convict.voyage?.departure_date
                    ? moment(convict.voyage.departure_date).format('MMM YYYY')
                    : 'Unknown'
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="absolute left-3/4">
          <div class="absolute -left-2 size-4 -translate-y-1/2 rounded-full bg-zinc-300">
            <div class="absolute -left-9 translate-y-1/2 text-center">
              <div class="text-zinc-400">Arrival</div>
              <div class="w-24 text-zinc-500">
                {{
                  convict.voyage?.arrival_date
                    ? moment(convict.voyage.arrival_date).format('MMM YYYY')
                    : 'Unknown'
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="absolute right-0 size-5 -translate-y-1/2 rounded-full bg-zinc-300">
          <div class="absolute right-0 -translate-y-full text-right">
            <div class="text-zinc-400">Death</div>
            <div class="w-20 text-zinc-500">
              {{
                convict.date_of_death ? moment(convict.date_of_death).format('MMM YYYY') : 'Unknown'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 sm:mt-5">
      <div class="flex w-full flex-col gap-x-5 gap-y-8 lg:flex-row">
        <div class="relative w-full border border-zinc-200 bg-white p-4 pt-6 sm:p-6">
          <div
            class="absolute inset-x-0 -top-4 ml-2 w-3/4 whitespace-nowrap border bg-muted/50 p-1 pl-3 font-serif text-lg font-medium text-black backdrop-blur-sm"
          >
            Personal Information
          </div>
          <div class="mt-3 py-2 lg:mt-0">
            <span class="font-bold"> Name: </span>
            <span> {{ convict.name }}</span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Aliases: </span>
            <span>
              {{ aliasnames() }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Gender: </span>
            <span>
              {{ convict.gender === 'u' ? 'Unknown' : convict.gender === 'm' ? 'Male' : 'Female' }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Born: </span>
            <span>
              {{
                convict.date_of_birth
                  ? moment(convict.date_of_birth).format('Do MMM YYYY')
                  : 'Unknown'
              }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Death: </span>
            <span>
              {{
                convict.date_of_death
                  ? moment(convict.date_of_death).format('Do MMM YYYY')
                  : 'Unknown'
              }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Age: </span>
            <span> {{ age() }} </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Occupation: </span>
            <Link
              v-if="convict.occupation"
              :href="route('occupations.show', convict.occupation.slug)"
              class="font-bold text-link hover:text-link-hover hover:underline"
            >
              {{ convict.occupation.name }}
            </Link>
            <span v-else>Unknown</span>
          </div>
        </div>

        <div class="relative w-full border border-zinc-200 bg-white p-4 pt-6 sm:p-6">
          <div
            class="absolute inset-x-0 -top-4 ml-2 w-3/4 whitespace-nowrap border bg-muted/50 p-1 pl-3 font-serif text-lg font-medium text-black backdrop-blur"
          >
            Crime
          </div>
          <div class="py-2">
            <span class="font-bold"> Crime: </span>

            <Link
              v-if="convict.crime"
              :href="route('crimes.show', convict.crime.slug)"
              class="font-bold text-link hover:text-link-hover hover:underline"
            >
              {{ convict.crime.name }}
            </Link>
            <span v-else>Unknown</span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Convicted at: </span>
            <span>
              {{ convict.convicted_at ? convict.convicted_at.name : 'Unknown' }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Sentence term: </span>
            <span> {{ convict.term ? convict.term : 'Unknown' }} </span>
          </div>
        </div>

        <div class="relative w-full border border-zinc-200 bg-white p-4 pt-6 sm:p-6">
          <div
            class="absolute inset-x-0 -top-4 ml-2 w-3/4 whitespace-nowrap border bg-muted/50 p-1 pl-3 font-serif text-lg font-medium text-black backdrop-blur"
          >
            Voyage
          </div>
          <div class="py-2">
            <div v-if="convict.voyage?.ship">
              <span class="font-bold">Departed: </span>
              <Link
                :href="route('ships.voyages.show', [convict.voyage.ship, convict.voyage])"
                class="font-bold text-link hover:text-link-hover hover:underline"
              >
                {{ moment(convict.voyage.departure_date).format('Do MMM YYYY') }}
              </Link>
            </div>
            <div v-else><span class="font-bold"> Voyage: </span> Unknown</div>
          </div>

          <div class="py-2">
            <div v-if="convict.voyage?.ship">
              <span class="font-bold">Ship: </span>
              <Link
                v-if="convict.voyage?.ship"
                :href="route('ships.show', convict.voyage?.ship.url_name)"
                class="font-bold text-link hover:text-link-hover hover:underline"
              >
                {{ convict.voyage.ship.name }}
              </Link>
            </div>
            <div v-else><span class="font-bold"> Ship: </span> Unknown</div>
          </div>

          <div class="py-2">
            <span class="font-bold"> Arrival: </span>
            <span>
              {{
                convict.voyage?.arrival_date
                  ? moment(convict.voyage.arrival_date).format('Do MMM YYYY')
                  : 'Unknown'
              }}
            </span>
          </div>
          <div class="py-2">
            <span class="font-bold"> Place of Arrival: </span>
            <span>
              {{ convict.voyage?.place_of_arrival?.name ?? 'Unknown' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
