<script setup>
import { Dropdown, DropdownItem } from '@codinglabsau/ui'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
</script>

<template>
  <div>
    <Dropdown>
      <template #text>
        <span class="hidden sm:flex"> Browse by Surname</span>
        <span class="block sm:hidden"> Surname</span>
        <ChevronDownIcon class="-mr-1 ml-2 size-5" aria-hidden="true" />
      </template>
      <DropdownItem
        v-for="letter in letters"
        :key="letter"
        :href="route('convicts.index.surname', letter.toLowerCase())"
      >
        {{ letter }}
      </DropdownItem>
    </Dropdown>
  </div>
</template>
