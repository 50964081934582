<script setup>
import { computed } from 'vue'
import { UserCircleIcon } from '@heroicons/vue/24/solid'
import ReputationModal from '@/Components/Reputation/ReputationModal.vue'

const props = defineProps({
  user: Object,
})

const userReputation = props.user.reputation

const levels = [0, 100, 500, 5000]
const levelPositions = [100, 66.67, 33.33, 0]

const levelTitles = ['Convict', 'Ticket of Leave', 'Free Settler', 'Governor']

const getLevel = () => {
  const level = levels.findIndex((val, index) => {
    return index === levels.length - 1
      ? userReputation >= val
      : userReputation >= val && userReputation < levels[index + 1]
  })

  return level
}

const getProgress = () => {
  const level = getLevel()
  const startValue = levels[level]
  const nextValue = level === levels.length - 1 ? startValue : levels[level + 1]
  const range = nextValue - startValue

  return ((userReputation - startValue) / range) * (100 / (levels.length - 1))
}

const progressBarHeight = computed(() => {
  const level = getLevel()
  const progress = getProgress()
  const totalHeight = level * (100 / (levels.length - 1)) + progress

  return 100 - totalHeight // Adjust it as our progress bar goes from top to bottom.
})
</script>

<template>
  <div class="flex flex-col gap-10 px-4 py-6">
    <h3 class="flex items-center space-x-2 text-xl font-medium leading-6 text-gray-900">
      <span>Level Progression</span>
      <ReputationModal />
    </h3>

    <div class="mx-auto flex w-full justify-center py-4">
      <div class="-rounded-b-full relative z-10 h-96 w-2.5 bg-primary">
        <div
          :style="{ height: `${progressBarHeight}%` }"
          class="from absolute bottom-0 w-full bg-zinc-300"
        ></div>
        <div
          v-for="(level, index) in levels"
          :key="index"
          class="absolute h-1 w-full"
          :style="{ bottom: `${levelPositions[index]}%` }"
        >
          <UserCircleIcon
            class="absolute -left-5 -top-6 size-12 rounded-full fill-white"
            :class="{
              'bg-emerald-500': index <= getLevel(),
              'bg-gray-700': index > getLevel(),
            }"
          />
          <div class="absolute -top-4 left-10 w-32 text-sm">
            <div>{{ levelTitles[index] }}</div>
            <div>Level {{ index }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
