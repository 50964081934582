<script setup>
import { Link, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { Breadcrumbs, Container, Notifications } from '@codinglabsau/ui'

import Logo from '@/Components/Logo.vue'
import FacebookIcon from '@/Components/FacebookIcon.vue'
import GithubIcon from '@/Components/GithubIcon.vue'
import Feedback from '@/Components/Feedback.vue'
import NotificationDropdown from '@/Components/NotificationDropdown.vue'
import MessagesDropdown from '@/Components/MessagesDropdown.vue'
import AccountDropdown from '@/Components/Header/AccountDropdown.vue'
import MobileNav from '@/Components/Header/MobileNav.vue'
import DesktopNav from '@/Components/Header/DesktopNav.vue'
import GuestNav from '@/Components/Header/GuestNav.vue'

const authenticated = computed(() => {
  return usePage().props.auth.user
})

const mainNav = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const currentRoute = usePage().url // hack to keep guest nav current route reactive

  return [
    {
      name: 'Convicts',
      href: route('convicts.index'),
      current:
        route().current('convicts.*') &&
        !route().current('convicts.notes.*') &&
        !route().current('convicts.changes.*'),
      visible: true,
      nofollow: false,
    },
    {
      name: 'Ships',
      href: route('ships.index'),
      current: route().current('ships.*'),
      visible: true,
      nofollow: false,
    },
    {
      name: 'Voyages',
      href: route('voyages.index'),
      current: route().current('voyages.*'),
      visible: true,
      nofollow: false,
    },
    {
      name: 'Crimes',
      href: route('crimes.index'),
      current: route().current('crimes.*'),
      visible: true,
      nofollow: false,
    },
    {
      name: 'Occupations',
      href: route('occupations.index'),
      current: route().current('occupations.*'),
      visible: true,
      nofollow: false,
    },
    {
      name: 'Contributions',
      current: route().current('convicts.notes.*') || route().current('convicts.changes.*'),
      visible: authenticated.value !== null,
      children: [
        {
          name: 'Convict Notes',
          href: route('convicts.notes.index'),
          current: route().current('convicts.notes.index'),
          nofollow: true,
        },
        {
          name: 'Convict Changes',
          href: route('convicts.changes.index'),
          current: route().current('convicts.changes.index'),
          nofollow: true,
        },
      ],
    },
    {
      name: 'Members',
      href: route('members.index'),
      current: route().current('members.*'),
      visible: authenticated.value !== null,
      nofollow: true,
    },
  ]
})

const footerNav = {
  main: [
    { name: 'Supporters Club', href: route('supporters_club') },
    { name: 'Announcements', href: route('blog.index') },
    { name: 'About', href: route('about') },
    { name: 'Facts', href: route('facts') },
    { name: 'FAQ', href: route('faq') },
    { name: 'Resources', href: route('resources') },
    { name: 'Privacy', href: route('privacy') },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/ConvictRecords',
      icon: FacebookIcon,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/codinglabsau',
      icon: GithubIcon,
    },
  ],
}
</script>

<template>
  <div v-if="!authenticated" class="flex w-full justify-center bg-stone-800">
    <Link class="py-1 text-center text-sm text-white" :href="route('supporters_club')">
      📣 Introducing the Convict Records Supporters Club
    </Link>
  </div>

  <div class="flex min-h-screen flex-col bg-stone-100 font-sans antialiased">
    <div class="bg-white shadow">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
          <MobileNav :items="mainNav" />
          <DesktopNav :items="mainNav" />

          <!-- shared right nav -->
          <div class="flex items-center space-x-3 pr-2">
            <template v-if="authenticated">
              <MessagesDropdown />
              <NotificationDropdown />
              <AccountDropdown />
            </template>

            <template v-else>
              <GuestNav />
            </template>
          </div>
        </div>
      </div>
    </div>

    <Container
      v-if="$page.props.breadcrumbs.length > 0"
      class="w-full !py-0"
      aria-label="Breadcrumbs"
    >
      <Breadcrumbs
        :pages="$page.props.breadcrumbs"
        class="mx-auto overflow-x-auto whitespace-nowrap pt-4 capitalize text-white"
      />
    </Container>

    <main class="relative inset-x-0 grow">
      <slot />
    </main>

    <footer class="bg-black">
      <Container>
        <nav
          class="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6"
          aria-label="Footer"
        >
          <Link
            v-for="item in footerNav.main"
            :key="item.name"
            :href="item.href"
            class="text-gray-400 hover:text-white"
          >
            {{ item.name }}
          </Link>
        </nav>

        <div class="mt-16 flex justify-center gap-x-10">
          <a
            v-for="item in footerNav.social"
            :key="item.name"
            :href="item.href"
            class="text-gray-400 hover:text-gray-300"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="size-6" aria-hidden="true" />
          </a>
        </div>

        <div
          class="mt-8 flex flex-col border-t border-gray-600 pt-8 text-center md:flex-row md:items-center md:justify-between"
        >
          <Link href="/" class="justify-items-center text-gray-400 hover:text-gray-300">
            <Logo />
          </Link>

          <p
            class="mt-4 font-serif text-sm leading-6 tracking-widest text-gray-300 md:order-1 md:mt-0"
          >
            Convict Records is a software project by
            <a href="https://codinglabs.com.au" class="underline">Coding Labs.</a>
          </p>
        </div>
      </Container>
    </footer>

    <Notifications
      class="z-50"
      :info="$page.props.info"
      :success="$page.props.success"
      :warning="$page.props.warning"
      :errors="$page.props.errors"
    />
  </div>
  <Feedback v-if="$page.props.auth.user" />
</template>
