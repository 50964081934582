<script setup>
import { onMounted, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  collection: {
    type: Object,
    required: true,
  },
  filter: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
})

const computedOptions = props.options.map((person) => {
  return {
    id: person.replace(' ', '_').toLowerCase(),
    name: person,
  }
})

const selectedOption = ref(computedOptions[0])

const buildURL = () => {
  const url = new URL(props.collection.first_page_url)
  const searchParam = new URLSearchParams(url.search)

  if (selectedOption.value.name === computedOptions[0].name) {
    searchParam.delete('filter[' + props.filter + ']')
  } else {
    searchParam.set('filter[' + props.filter + ']', selectedOption.value.id)
  }

  return url.origin + url.pathname + '?' + searchParam.toString()
}
const applyFilter = (option) => {
  selectedOption.value = option
  router.get(buildURL())
}

const initialiseFilterValue = () => {
  const searchParam = new URLSearchParams(window.location.search)
  const filterValue = searchParam.get('filter[' + props.filter + ']')
  if (filterValue) {
    selectedOption.value = computedOptions.find((option) => option.id === filterValue)
  }
}

onMounted(() => {
  initialiseFilterValue()
})
</script>

<template>
  <Listbox v-model="selectedOption" as="div">
    <div class="relative mt-2">
      <div class="flex">
        <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <label
            for="name"
            class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Status
          </label>
          <span class="block truncate capitalize">{{ selectedOption.name }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-1 flex items-center">
            <ChevronUpDownIcon class="size-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
      </div>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="item in computedOptions"
            :key="item.id"
            v-slot="{ active, selected }"
            as="template"
            :value="item"
            @click="applyFilter(item)"
          >
            <li
              :class="[
                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9 capitalize',
              ]"
            >
              <div :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ item.name }}
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="size-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
