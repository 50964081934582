<script setup>
import { Head, router } from '@inertiajs/vue3'
import {
  Container,
  Date,
  Number,
  PrimaryButton,
  SecondaryButton,
  Select,
  Text,
  Textarea,
  WarningAlert,
} from '@codinglabsau/ui'
import { FormBuilder, Grid, useSchema } from '@codinglabsau/inertia-form-builder'
import ComboAxiosModelSearch from '@/Components/Forms/ComboAxiosModelSearch.vue'
import PageHeader from '@/Components/PageHeader.vue'
import SelectAxiosModelResults from '@/Components/Forms/SelectAxiosModelResults.vue'

const genderOptions = [
  { id: 'u', name: 'Unknown' },
  { id: 'm', name: 'Male' },
  { id: 'f', name: 'Female' },
]

const schema = useSchema({
  name: {
    component: Grid,
    schema: {
      firstname: Text,
      surname: Text,
      gender: {
        component: Select,
        props: {
          options: genderOptions,
        },
      },
    },
  },
  alias1: Text,
  alias2: Text,
  alias3: Text,
  alias4: Text,
  dates: {
    component: Grid,
    schema: {
      date_of_birth: {
        component: Date,
        props: {
          min: '1700-01-01',
          max: '1876-12-31',
          description: 'Leave blank if unknown',
        },
      },
      date_of_death: {
        component: Date,
        props: {
          min: '1780-01-01',
          max: '1930-12-31',
          description: 'Leave blank if unknown',
        },
      },
    },
  },
  occupation_id: {
    component: ComboAxiosModelSearch,
    props: {
      route: route('search.occupations'),
    },
  },
  ship_id: {
    component: ComboAxiosModelSearch,
    props: {
      route: route('search.ships'),
    },
  },
  voyage_id: {
    component: SelectAxiosModelResults,
    props: {
      searchId: () => schema.form.ship_id,
      searchRoute: route('search.voyages'),
      placeholder: 'Search for a Voyage',
      blank: 'No voyages found',
    },
    visible: () => schema.form.ship_id !== null,
  },
  crime_id: {
    component: ComboAxiosModelSearch,
    props: {
      route: route('search.crimes'),
    },
  },
  conviction: {
    component: Grid,
    schema: {
      convicted_at_id: {
        component: ComboAxiosModelSearch,
        props: {
          route: route('search.convicted-at'),
        },
      },
      term: {
        component: Number,
        props: {
          description: 'Sentence in years',
          placeholder: 'Years',
        },
      },
    },
  },
  source: {
    component: Textarea,
    props: {
      description: 'Provide references for the information provided (required)',
    },
  },
  notes: {
    component: Textarea,
    props: {
      description: 'Add any additional notes for this convict',
    },
  },
})

const submit = () => schema.form.post(route('convicts.store'))
const cancel = () => router.get(route('convicts.index'))
</script>

<template>
  <Head title="Add Convict" />
  <Container compact>
    <PageHeader title="Add Convict" />
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <WarningAlert v-if="$page.props.auth.user.level < 1">
        Changes will require moderation before they are visible
      </WarningAlert>

      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions="{ form }">
            <div class="flex justify-end space-x-2">
              <SecondaryButton @click="cancel"> Cancel</SecondaryButton>
              <PrimaryButton :loading="form.processing" type="submit"> Save</PrimaryButton>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>
</template>
