<script setup>
import { computed, ref } from 'vue'
import { Container, SecondaryButton } from '@codinglabsau/ui'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import SmallPagination from '@/Components/SmallPagination.vue'
import Show from '@/Components/Contributions/ConvictNote/Show.vue'
import NameFilter from '@/Components/Contributions/Filters/SearchFilter.vue'
import DropdownFilter from '@/Components/Contributions/Filters/DropdownFilter.vue'
import ConvictListItemNavigation from '@/Components/Contributions/ConvictListItemNavigation.vue'
import ReputationWarning from '@/Components/Reputation/ReputationWarning.vue'

const props = defineProps({
  convictNotes: {
    type: Object,
    required: true,
  },
  statusOptions: {
    type: Object,
    required: true,
  },
})

const filters = ref(Object.keys(route().params.filter ?? {}).length)
const selectedNote = ref(props.convictNotes.data[0] || null)

const filterOptions = ['pending', 'approved', 'pending approval', 'pending rejection']

const computedNavigation = computed(() => {
  return props.convictNotes.data.map((note) => {
    return {
      current: note.id === selectedNote.value?.id,
      ...note,
    }
  })
})

const sidebarOpen = ref(false)

const selectNote = (item) => {
  sidebarOpen.value = false
  selectedNote.value = item
}
</script>

<template>
  <HeadMetaTags
    title="Convict Notes"
    description="Contributed notes for convicts."
    :canonical="route('convicts.notes.index')"
    :url="route('convicts.notes.index')"
  />
  <Container class="lg:flex">
    <!--      mobile sidebar-->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex h-screen w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <aside class="flex h-dvh flex-col space-y-4 bg-white">
                <div class="space-y-3 px-4 py-2">
                  <NameFilter :collection="convictNotes" filter="convictSearch" />
                  <DropdownFilter
                    :options="filterOptions"
                    :collection="convictNotes"
                    filter="status"
                  />
                </div>
                <hr class="border-t border-gray-200" />
                <nav class="flex flex-1 grow flex-col overflow-hidden pl-4">
                  <ul role="list" class="space-y-2 overflow-y-auto">
                    <li v-if="computedNavigation.length > 0" class="space-y-2">
                      <div v-for="item in computedNavigation" :key="item.id">
                        <ConvictListItemNavigation
                          :change="item"
                          :description="item.note"
                          @click="selectNote(item)"
                        />
                      </div>
                    </li>
                  </ul>
                </nav>
                <SmallPagination :collection="props.convictNotes" class="px-5" />
              </aside>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="w-full">
      <PageHeader title="Convict Notes">
        <button
          type="button"
          class="-m-2.5 p-2.5 pr-5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </PageHeader>
      <!-- Static sidebar for desktop -->
      <div class="flex">
        <div class="hidden shrink-0 lg:inset-y-0 lg:flex lg:w-72 lg:flex-col">
          <aside class="flex grow flex-col space-y-4 border-r border-gray-200 bg-white">
            <div class="space-y-4 px-4 pt-4">
              <NameFilter :collection="convictNotes" filter="convictSearch" />
              <DropdownFilter :options="filterOptions" :collection="convictNotes" filter="status" />
            </div>
            <hr class="border-t border-gray-200" />

            <nav class="flex flex-1 grow flex-col pl-4">
              <ul role="list" class="flex flex-1 flex-col space-y-2 overflow-y-auto">
                <li v-if="computedNavigation.length > 0" class="space-y-2">
                  <div v-for="item in computedNavigation" :key="item.convict.name">
                    <ConvictListItemNavigation
                      :change="item"
                      :description="item.note"
                      @click="selectedNote = item"
                    />
                  </div>
                </li>
              </ul>
            </nav>
            <SmallPagination :collection="props.convictNotes" class="px-5" />
          </aside>
        </div>

        <main class="grow space-y-2 bg-white px-4 pt-3 sm:px-6 lg:px-8 lg:py-6">
          <ReputationWarning />

          <!-- Main area -->
          <Show
            v-if="selectedNote"
            :note="selectedNote"
            @moderated="selectedNote = convictNotes.data[0]"
          />
          <div
            v-else
            class="flex flex-col items-center justify-center space-y-1 rounded-lg border-2 border-dashed border-gray-300 py-12 text-center text-gray-300"
          >
            <span>
              {{ !selectedNote && !filters ? 'There are no convict notes' : 'No results found' }}
            </span>
            <SecondaryButton v-if="filters" :href="route('convicts.notes.index')">
              Clear Filters
            </SecondaryButton>
          </div>
        </main>
      </div>
    </div>
  </Container>
</template>
