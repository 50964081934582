<script setup>
import { useForm } from '@inertiajs/vue3'
import { Container, PrimaryButton } from '@codinglabsau/ui'

import VoyagesTable from '@/Components/Voyages/Table.vue'
import { ship_image } from '@/helpers'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import SubSection from '@/Components/SubSection.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  ship: Object,
  voyages: Object,
})

const form = useForm({
  ship_id: props.ship.id,
})

const createVoyage = () => {
  form.get(route('voyages.create'))
}
</script>

<template>
  <HeadMetaTags
    :title="ship.name"
    :description="`A history of the ship ${ship.name} as it transported convicts to Australia.`"
    :canonical="route('ships.show', ship)"
    :url="route('ships.show', ship)"
  />

  <Container>
    <PageHeader :title="ship.name">
      <template #actions>
        <PrimaryButton rel="nofollow" :href="route('ships.edit', ship)"> Edit</PrimaryButton>
      </template>
    </PageHeader>

    <article class="bg-white p-4 sm:px-8">
      <section
        class="w-md my-8 flex flex-col items-center justify-center gap-3 sm:flex-row sm:items-start"
      >
        <img :src="ship_image(ship.image)" class="mr-4 h-36 w-auto rounded-lg" :alt="ship.name" />

        <div v-if="ship.description" class="prose w-4/5 whitespace-pre-line sm:w-3/5">
          <p>
            {{ ship.description }}
          </p>
        </div>
      </section>

      <SubSection title="Voyages">
        <template #actions>
          <PrimaryButton @click="createVoyage"> Add Voyage</PrimaryButton>
        </template>

        <VoyagesTable :voyages="voyages" hide-paginator :searchable="false" :sortable="false" />
      </SubSection>
    </article>
  </Container>
</template>
