<script setup>
import { ref } from 'vue'
import { History } from 'lucide-vue-next'
import moment from 'moment'
import { Button, Sheet, SheetContent, SheetDescription, SheetTitle } from '@codinglabsau/gooey'

defineProps({
  versionable: {
    type: Object,
    required: true,
  },
  version: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits(['update:version'])

const sheetOpen = ref(false)

const updateVersion = (version) => {
  emits('update:version', version)
  sheetOpen.value = false
}

const changelog = (version) => {
  return Object.keys(version.new_values)
    .filter((key) => key !== 'slug')
    .join(', ')
}
</script>

<template>
  <Sheet :open="sheetOpen" @update:open="sheetOpen = !sheetOpen">
    <SheetContent>
      <SheetTitle class="flex items-center space-x-2 text-xl">
        Compare {{ versionable.versions.length }} versions
      </SheetTitle>

      <SheetDescription>
        <ul class="divide-y">
          <li v-for="item in versionable.versions" :key="item.id">
            <Button
              variant="ghost"
              class="grid h-auto w-full grid-cols-1 text-left"
              :class="{
                'bg-blue-50 text-black': version === item.version,
              }"
              @click="updateVersion(item.version)"
            >
              <time class="whitespace-nowrap text-sm font-bold">
                {{ moment(item.created_at).format('DD MMM, YYYY') }}
              </time>
              <span>{{ item.user.name }}</span>
              <span class="text-xs italic">
                {{ changelog(item) }}
              </span>
            </Button>
          </li>
        </ul>
      </SheetDescription>
    </SheetContent>
  </Sheet>

  <Button variant="secondary" class="gap-x-1" @click="sheetOpen = !sheetOpen">
    <History />

    <span v-if="versionable.current_version === version"> Version History </span>

    <span v-else> Viewing version {{ version }} </span>
  </Button>
</template>
