<script setup>
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import BaseIndexSearch from '@/Components/IndexSearch.vue'

const error = ref('')

const submit = (search) => {
  error.value = ''
  if (search && search.length >= 3) {
    router.visit(route('ships.index') + '?filter[q]=' + search)
  } else {
    error.value = 'Search must have at least 3 characters.'
  }
}
</script>

<template>
  <BaseIndexSearch :input-props="{ placeholder: 'Ship name', error: error }" @submit="submit" />
</template>
