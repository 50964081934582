<script setup>
import { computed, ref } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import { HeartIcon } from '@heroicons/vue/24/outline'
import { Button } from '@codinglabsau/gooey'

const props = defineProps({
  subscription: {
    type: String,
    required: false,
    default: null,
  },
  variant: {
    type: String,
    default: 'bordered',
  },
  dark: {
    type: Boolean,
    default: false,
  },
  showFeatures: {
    type: Boolean,
    default: true,
  },
})

const user = usePage().props.auth.user

const isSubscribed = user?.supporter

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const userFrequency = frequencies.find((f) => f.value === props.subscription)
const frequency = ref(isSubscribed && props.subscription ? userFrequency : frequencies[0])

const tiers = computed(() => {
  return [
    {
      name: 'Supporters Club',
      id: 'supporter',
      current: isSubscribed,
      visible: true,
      href: user
        ? route('account.billing.subscriptions.checkout', {
            user: user,
            plan: frequency.value?.value,
          })
        : route('account.billing.show'),
      price: { monthly: '$10', annually: '$100' },
      description: 'Support the ongoing development of Convict Records.',
      features: [
        'Access to new exclusive features as they are released',
        'Start new conversations with other members',
        'Supporter badge attached to your account',
        'Your name will be proudly displayed as a Supporter on our website',
      ],
    },
  ]
})
</script>

<template>
  <div
    v-for="tier in tiers.filter((tier) => tier.visible)"
    :key="tier.id"
    class="h-fit rounded-lg p-4 md:w-fit md:p-8"
    :class="{
      'border border-gray-200': variant === 'bordered',
      'bg-muted/10': variant === 'solid',
    }"
  >
    <div
      :id="tier.id"
      class="text-2xl font-semibold"
      :class="{
        'text-gray-900': !dark,
        'text-white': dark,
      }"
    >
      {{ tier.name }}
    </div>

    <p class="mt-4">{{ tier.description }}</p>

    <div v-if="tier.price">
      <div
        v-if="isSubscribed && subscription"
        class="mt-4 gap-x-1 rounded-full bg-stone-600 p-1 px-2.5 text-center text-xs font-semibold capitalize leading-5 text-white ring-1 ring-inset ring-gray-200"
      >
        {{ subscription }}
      </div>
      <RadioGroup
        v-else
        v-model="frequency"
        class="mt-4 grid grid-cols-2 gap-x-1 rounded-lg p-1 text-center text-xs font-medium leading-5"
        :class="{
          'bg-muted/10': dark,
          'bg-muted': !dark,
        }"
      >
        <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
        <RadioGroupOption
          v-for="option in frequencies"
          :key="option.value"
          v-slot="{ checked }"
          as="template"
          :value="option"
        >
          <div
            :class="{
              'bg-white font-medium text-black': checked,
              'text-muted/40': !checked && dark,
              'text-slate-400': !checked && !dark,
            }"
            class="cursor-pointer rounded-lg px-2.5 py-1"
          >
            <span>{{ option.label }}</span>
          </div>
        </RadioGroupOption>
      </RadioGroup>

      <p class="mt-6 flex items-baseline justify-center gap-x-1">
        <span
          class="text-4xl font-bold tracking-tight md:text-5xl"
          :class="{
            'text-gray-900': !dark,
            'text-white': dark,
          }"
        >
          {{ tier.price[frequency.value] }}
        </span>
        <span class="font-semibold">
          {{ frequency.priceSuffix }}
        </span>
      </p>
    </div>

    <ul v-if="!!showFeatures" role="list" class="mt-8 space-y-3 text-sm xl:mt-10">
      <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
        <CheckIcon class="h-6 w-5 flex-none text-stone-600" aria-hidden="true" />
        {{ feature }}
      </li>
    </ul>

    <div v-if="tier.href" class="flex w-full justify-center">
      <div v-if="isSubscribed" class="space-y-3">
        <Button
          rel="nofollow"
          class="mt-10 w-full"
          as="a"
          :href="route('account.billing.portal', user)"
        >
          <div>Manage subscription</div>
          <ArrowTopRightOnSquareIcon class="ml-1 size-5" aria-hidden="true" />
        </Button>

        <slot name="end-date" />
      </div>

      <Button
        v-else
        rel="nofollow"
        :aria-describedby="tier.id"
        class="mt-10 w-full"
        :href="tier.href"
        as="a"
      >
        <HeartIcon class="mr-1 size-6" aria-hidden="true" />
        <div>Become a Supporter</div>
      </Button>
    </div>
  </div>
</template>
