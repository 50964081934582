<script setup>
import { Head, useForm } from '@inertiajs/vue3'
import { Button, Input, Label } from '@codinglabsau/gooey'
import GuestLayout from '@/Layouts/Guest.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'

const props = defineProps({
  email: String,
  token: String,
})

const form = useForm({
  token: props.token,
  email: props.email,
  password: '',
  password_confirmation: '',
})

const submit = () => {
  form.post(route('password.update'), {
    onFinish: () => form.reset('password', 'password_confirmation'),
  })
}

defineOptions({ layout: GuestLayout })
</script>

<template>
  <Head title="Reset Password" />

  <BreezeValidationErrors class="mb-4" />

  <form @submit.prevent="submit">
    <div>
      <Label for="email" value="Email" />
      <Input
        id="email"
        v-model="form.email"
        type="email"
        class="mt-1 block w-full"
        placeholder="Email"
        required
        autocomplete="username"
      />
    </div>

    <div class="mt-4">
      <Label for="password" value="Password" />
      <Input
        id="password"
        v-model="form.password"
        type="password"
        class="mt-1 block w-full"
        placeholder="Password"
        required
        autofocus
        autocomplete="new-password"
      />
    </div>

    <div class="mt-4">
      <Label for="password_confirmation" value="Confirm Password" />
      <Input
        id="password_confirmation"
        v-model="form.password_confirmation"
        type="password"
        class="mt-1 block w-full"
        placeholder="Confirm password"
        required
        autocomplete="new-password"
      />
    </div>

    <div class="mt-4 flex items-center justify-end">
      <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Reset Password
      </Button>
    </div>
  </form>
</template>
