<script setup>
import { Link, usePage } from '@inertiajs/vue3'
import { PrimaryButton, SecondaryButton, WarningAlert } from '@codinglabsau/ui'
import { useSchema } from '@codinglabsau/inertia-form-builder'
import SubSection from '@/Components/SubSection.vue'
import { computed } from 'vue'

const props = defineProps({
  change: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits(['moderated'])

const user = usePage().props.auth.user

const attributeLabel = (item) => {
  return item ?? '-'
}

const schema = useSchema({})

const approve = () => {
  schema.form.post(route('convicts.changes.approve', { convictHistory: props.change.id }), {
    onSuccess: () => {
      emits('moderated')
    },
  })
}

const reject = () => {
  schema.form.post(route('convicts.changes.reject', { convictHistory: props.change.id }), {
    onSuccess: () => {
      emits('moderated')
    },
  })
}

const canModerate = computed(() => {
  const isModerator = props.change.moderator?.id === user?.id

  const isContributor = props.change.contributor.id === user?.id

  const alreadyModeratedAndUserIsLevel0 = props.change.moderator !== null && user?.level === 0

  return !(isModerator || isContributor || alreadyModeratedAndUserIsLevel0)
})
</script>

<template>
  <SubSection :title="change?.convict.name" as="h2">
    <template #actions>
      <div v-if="canModerate" class="space-x-3">
        <SecondaryButton @click="reject()"> <span class="text-xs">Reject</span></SecondaryButton>
        <PrimaryButton @click="approve()"> <span class="text-xs">Approve</span></PrimaryButton>
      </div>
      <div v-else class="text-sm text-gray-500">
        <span v-if="!user"> You must be logged in to moderate. </span>
        <span v-else-if="change.contributor.id === user?.id">
          You cannot moderate your own changes.
        </span>
        <span v-else-if="change.moderator?.id === user?.id">
          You have already moderated this change.
        </span>
        <span v-else-if="change.moderator?.id !== null && user?.level === 0">
          You will need a higher reputation to moderate this change.
        </span>
      </div>
    </template>
    <div>
      <div class="space-y-2">
        <WarningAlert v-if="props.change.conflicts.length > 0">
          <div>
            These will not be updated due to conflicts:
            {{ change.conflicts.join(', ') }}
          </div>
          <div>These conflicts can occur when the attribute has already been updated.</div>
        </WarningAlert>
      </div>
      <div class="space-y-2 pt-4 text-sm">
        <div>
          Convict
          <Link
            :href="route('convicts.show', change.convict.slug)"
            class="text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            {{ change?.convict.name }}
          </Link>
        </div>
        <div>
          Status <span class="text-gray-600">{{ change.status }}</span>
        </div>
        <div>
          Contributor
          <Link
            :href="route('members.show', change.contributor)"
            class="text-link visited:text-visited hover:text-link-hover hover:underline"
          >
            {{ change.contributor.name }}
          </Link>
        </div>
        <div>
          Changes
          <span class="text-gray-600">
            {{ change.changelog !== 'New Record' ? change.changelog.join(', ') : change.changelog }}
          </span>
        </div>

        <!--        <div>-->
        <!--          Contribution Date:-->
        <!--          <span class="text-gray-600">{{ moment(change.updated_at).format('Do MMM YYYY') }}</span>-->
        <!--        </div>-->
      </div>

      <div class="flow-root">
        <div class="overflow-x-auto">
          <section class="inline-block min-w-full">
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  ></th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Changes
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Current
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Updated
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="attribute in change.changes"
                  :key="attribute.label"
                  :class="[
                    attribute.updated ? 'bg-green-50' : '',
                    attribute.conflict && attribute.updated ? 'bg-yellow-50' : '',
                  ]"
                >
                  <td class="py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                    {{ attribute.label }}
                  </td>
                  <td class="max-w-32 break-words p-2 text-xs text-gray-900">
                    {{ attributeLabel(attribute.requested) }}
                  </td>
                  <td class="max-w-32 break-words p-2 text-xs text-gray-900">
                    {{ attributeLabel(attribute.current) }}
                  </td>
                  <td class="max-w-32 break-words p-2 text-xs text-gray-900">
                    {{ attributeLabel(attribute.result) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>
  </SubSection>
</template>
