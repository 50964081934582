<script setup>
import { ref, watch } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { ChevronDoubleDownIcon, ChevronDoubleRightIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { Container, PrimaryButton, WarningAlert } from '@codinglabsau/ui'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import AxiosSearch from '@/Components/AxiosSearch.vue'

const props = defineProps({
  occupation: {
    type: Object,
    required: true,
  },
})

const selectedOccupation = ref(null)

const form = useForm({
  occupation_id: props.occupation.id,
  target_occupation_id: null,
})

watch(selectedOccupation, (value) => {
  form.target_occupation_id = value ? value.id : null
})

const submit = () => {
  form.put(route('occupations.merge.update', { occupation: props.occupation }))
}
</script>

<template>
  <HeadMetaTags
    :title="'Merge ' + occupation.name"
    description="Merging Occupations."
    :canonical="route('occupations.merge.edit', occupation.slug)"
    :url="route('occupations.merge.edit', occupation.slug)"
  />

  <Container>
    <PageHeader :title="'Merge ' + occupation.name" />
    <div class="bg-white p-4">
      <div class="mx-auto max-w-2xl space-y-4">
        <div>
          Merging will move all Convicts from
          <span class="font-semibold capitalize">{{ occupation.name }}</span>
          into
          <span :class="selectedOccupation ? 'font-semibold capitalize' : ''">
            {{ selectedOccupation ? selectedOccupation.label : 'the New Occupation' }} </span
          >.
        </div>
        <form class="space-y-2" @submit.prevent>
          <div
            class="flex flex-col items-center justify-center space-y-5 p-5 sm:flex-row sm:space-x-10 sm:space-y-0"
          >
            <div>
              <div class="rounded-lg border p-6">
                <div class="text-lg font-semibold capitalize">{{ occupation.name }}</div>
                <div class="text-sm text-gray-500">
                  {{ occupation.convicts_count }} Convicts Assigned
                </div>
              </div>
            </div>
            <ChevronDoubleRightIcon class="hidden size-8 sm:block" />
            <ChevronDoubleDownIcon class="size-8 sm:hidden" />
            <div>
              <AxiosSearch
                v-if="!selectedOccupation"
                v-model="selectedOccupation"
                :route="route('search.occupations')"
              >
                <template #row="{ item }">
                  <div class="flex items-center justify-between">
                    <div>{{ item.label }}</div>
                    <div class="text-sm text-gray-500">{{ item.convicts_count }} convicts</div>
                  </div>
                </template>
              </AxiosSearch>
              <div v-else>
                <div class="relative rounded-lg border p-6">
                  <XMarkIcon
                    class="absolute -right-1.5 -top-1.5 size-5 cursor-pointer rounded-lg border bg-white"
                    @click="selectedOccupation = null"
                  />
                  <div class="text-lg font-semibold capitalize">{{ selectedOccupation.label }}</div>
                  <div class="text-sm text-gray-500">
                    {{ selectedOccupation.convicts_count }} Convicts Assigned
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedOccupation">
            <div>
              <WarningAlert>
                <div v-if="selectedOccupation.id === occupation.id" class="text-right text-red-500">
                  Cannot merge a Occupation with itself.
                </div>
                <ul v-else class="list-disc pl-2">
                  <li>
                    {{ selectedOccupation.convicts_count }} Convicts Occupations will be updated to
                    <span class="font-semibold capitalize">{{ selectedOccupation.label }} </span>.
                  </li>
                  <li>
                    <span class="font-semibold capitalize"> {{ occupation.name }} </span> will be
                    removed.
                  </li>
                  <li>This action cannot be undone.</li>
                </ul>
              </WarningAlert>
            </div>
          </div>
          <div
            v-if="selectedOccupation && selectedOccupation.id !== occupation.id"
            class="flex justify-end"
          >
            <PrimaryButton :loading="form.processing" @click="submit"> Confirm Merge</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  </Container>
</template>
