<script setup>
import { Link, router, usePage } from '@inertiajs/vue3'
import moment from 'moment'

import { Container, DataTable, SecondaryButton } from '@codinglabsau/ui'

import SearchFilter from '@/Components/Filters/SearchFilter.vue'
import SubSection from '@/Components/SubSection.vue'
import MembersCard from '@/Components/Users/MembersCard.vue'
import UserAvatar from '@/Components/Users/UserAvatar.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  users: Array,
  ranks: Array,
  supporters: Object,
  canSuspend: Boolean,
})

const tableConfig = {
  columns: [
    {
      text: 'Member',
      value: 'name',
      compact: true,
    },
    {
      text: 'Rank',
      value: 'rank',
    },
    {
      text: 'Reputation',
      value: 'reputation',
      sortable: true,
    },
    {
      text: 'Joined',
      value: 'created_at',
      sortable: true,
    },
  ],
  filters: [
    {
      component: 'dropdown',
      label: 'Rank',
      key: 'rank',
      options: props.ranks.map((rank) => ({
        label: rank.name,
        value: rank.id,
      })),
    },
  ],
  customFilters: [
    {
      component: SearchFilter,
      label: 'Name Search',
      key: 'name',
    },
  ],
  collection: props.users,
  actions: [
    {
      text: 'Manage',
      value: 'loadSuspension',
      enabled: (item) => props.canSuspend && usePage().props.auth.user.id !== item.id,
    },
  ],
}

const loadSuspension = (user) => {
  router.get(route('members.suspend.show', { user: user }))
}
</script>

<template>
  <HeadMetaTags
    title="Members Wall"
    description="Meet the Convict Records community! Explore profiles of contributors and ancestry enthusiasts who help uncover, preserve, and expand Australia’s convict history."
    :canonical="route('members.index')"
    :url="route('members.index')"
  />

  <Container>
    <PageHeader title="Members Wall" />
    <div class="bg-white p-4">
      <SubSection title="Supporters Club">
        <template #actions>
          <SecondaryButton :href="route('members.supporters.index')"> View All </SecondaryButton>
        </template>
        <div
          class="flex flex-col items-center justify-center gap-3 sm:grid sm:grid-cols-2 lg:grid-cols-3"
        >
          <MembersCard
            v-for="supporter in supporters"
            :key="supporter.id"
            class="w-screen xs:w-full"
            :user="supporter"
          />
        </div>
      </SubSection>
      <SubSection title="Members">
        <DataTable v-bind="tableConfig" @load-suspension="loadSuspension">
          <template #item.name="{ item }">
            <Link
              class="text-link visited:text-visited hover:text-link-hover"
              rel="nofollow"
              :href="route('members.show', item.id)"
            >
              <div class="flex w-full items-center justify-start xs:flex-row">
                <UserAvatar class="mr-3" :user="item" small />
                <div class="truncate">
                  {{ item.name }}
                </div>
              </div>
            </Link>
          </template>
          <template #item.reputation="{ item }">
            {{ parseInt(item.reputation).toLocaleString() }}
          </template>
          <template #item.created_at="{ item }">
            {{ moment(item.created_at).format('MMM YYYY') }}
          </template>
        </DataTable>
      </SubSection>
    </div>
  </Container>
</template>
