<script setup>
import { computed } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import { user_image } from '@/helpers'
import { Container, DataTable, PrimaryButton } from '@codinglabsau/ui'

import SupporterBadge from '@/Components/Supporters/SupporterBadge.vue'
import LevelProgression from '@/Components/Users/LevelProgression.vue'
import SubSection from '@/Components/SubSection.vue'
import PageHeader from '@/Components/PageHeader.vue'
import ReadMoreText from '@/Components/ReadMoreText.vue'

const props = defineProps({
  claims: Object,
  user: Object,
})

const claimsTableConfig = computed(() => ({
  columns: [
    {
      value: 'name',
      text: 'Convict Claimed',
      compact: true,
    },
    {
      value: 'description',
      text: 'Description',
    },
  ],
  collection: props.claims,
  actions: [
    {
      text: 'Disclaim',
      value: 'disclaim',
    },
  ],
}))

const disclaimConvict = (claim) => {
  router.delete(route('convicts.disclaim', claim.id))
}
</script>

<template>
  <Container>
    <PageHeader title="Profile">
      <SupporterBadge v-if="user.supporter" class="size-8" />
      <template #actions>
        <PrimaryButton rel="nofollow" class="!py-1" :href="route('account.edit')">
          Edit
        </PrimaryButton>
      </template>
    </PageHeader>

    <section class="flex w-full flex-col lg:flex-row">
      <section class="lg:w-3/5">
        <div class="overflow-hidden rounded-b-lg bg-white shadow lg:rounded-br-none">
          <div class="border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Avatar</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <img class="size-32 rounded-lg" :src="user_image(user.image)" :alt="user.name" />
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Name</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ user.name }}
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Email</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ user.email }}
                  <Link
                    v-if="!$page.props.auth.user.verified"
                    class="text-link underline hover:text-link-hover"
                    :href="route('verification.notice')"
                  >
                    Verify &rarr;
                  </Link>
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Reputation</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ parseInt(user.reputation).toLocaleString() }}
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Convict Contributions</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ parseInt(user.convict_histories_count).toLocaleString() }}
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-base font-medium text-gray-900">Convict Notes</dt>
                <dd class="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ parseInt(user.convict_notes_count).toLocaleString() }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section
        class="mt-4 rounded-lg bg-white shadow lg:mt-0 lg:w-2/5 lg:rounded-t-none lg:rounded-bl-none"
      >
        <LevelProgression :user="user" />
      </section>
    </section>

    <SubSection v-if="claims.data.length > 0" title="Claims">
      <DataTable v-bind="claimsTableConfig" @disclaim="disclaimConvict">
        <template #item.name="{ item }">
          <Link
            class="text-nowrap text-primary visited:text-visited"
            :href="route('convicts.show', item.slug)"
          >
            {{ item.name }}
          </Link>
        </template>
        <template #item.description="{ item }">
          <div class="max-w-64 sm:max-w-none">
            <ReadMoreText :text="item.description" line-clamp="3" />
          </div>
        </template>
      </DataTable>
    </SubSection>
  </Container>
</template>
