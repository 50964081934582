<script setup>
import { Head, router } from '@inertiajs/vue3'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { Container, PrimaryButton, SecondaryButton, WarningAlert } from '@codinglabsau/ui'
import Files from '@/Components/Files/Files.vue'
import PageHeader from '@/Components/PageHeader.vue'
import { computed } from 'vue'

const props = defineProps({
  convict: {
    type: Object,
    required: true,
  },
  supportedMimeTypes: String,
})

const schema = useSchema({
  files: {
    component: Files,
    fieldset: {
      files: props.convict.files,
      uploads: [],
    },
    showLabel: false,
    props: {
      supportedMimeTypes: props.supportedMimeTypes,
    },
  },
})

const submit = () => schema.form.put(route('convicts.files.update', props.convict))
const cancel = () => router.get(route('convicts.show', props.convict.slug))

const title = computed(() => props.convict.firstname + ' ' + props.convict.surname + ' Photos')
</script>

<template>
  <Head :title="title" />

  <Container>
    <PageHeader :title="title" />
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <WarningAlert v-if="$page.props.auth.user.level < 1">
        Changes will require moderation before they are visible
      </WarningAlert>

      <form @submit.prevent="submit">
        <FormBuilder :schema="schema" class="!max-w-full">
          <template #actions="{ form }">
            <div class="flex justify-end space-x-2">
              <SecondaryButton @click="cancel"> Cancel</SecondaryButton>
              <PrimaryButton :loading="form.processing" type="submit"> Save</PrimaryButton>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>
</template>
