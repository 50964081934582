<script setup>
import { ref } from 'vue'

const props = defineProps({
  headers: {
    type: Array,
    required: true,
  },
})

const activeTab = ref(0)
</script>

<template>
  <div class="space-y-4">
    <div class="flex w-fit gap-4 rounded bg-muted/20 p-1.5 text-base">
      <div
        v-for="(header, index) in headers"
        :key="index"
        :class="{
          'bg-muted': activeTab === index,
          'text-muted/80': activeTab !== index,
          'cursor-pointer': true,
        }"
        class="rounded px-3 py-1.5"
        @click="activeTab = index"
      >
        {{ header }}
      </div>
    </div>

    <div v-for="(header, index) in props.headers" v-show="activeTab === index" :key="index">
      <slot :name="header" />
    </div>
  </div>
</template>
