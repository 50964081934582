<script setup>
import moment from 'moment'
import {
  ArrowLeftEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/vue/24/solid'
import { Sailboat } from 'lucide-vue-next'
import { Container, PrimaryButton } from '@codinglabsau/ui'
import { ship_image } from '@/helpers'
import ConvictsTable from '@/Components/Convicts/Table.vue'
import VoyagesTable from '@/Components/Voyages/Table.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import SubSection from '@/Components/SubSection.vue'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  ship: Object,
  voyage: Object,
  voyages: Object,
  convicts: Object,
})

const timeline = [
  {
    label: `Set sail`,
    content: moment(props.voyage.departure_date).format('Do MMM YYYY'),
    datetime: props.voyage.departure_date,
    icon: ArrowRightStartOnRectangleIcon,
    iconBackground: 'bg-stone-400',
  },
  {
    label: 'In transit',
    content: `${props.voyage.days_at_sea} days`,
    icon: Sailboat,
    iconBackground: 'bg-stone-400',
  },
  {
    label: props.voyage.place_of_arrival?.name ?? 'Arrived at unknown port',
    content: props.voyage.arrival_date
      ? moment(props.voyage.arrival_date).format('Do MMM YYYY')
      : 'Unknown',
    datetime: props.voyage.arrival_date,
    icon: ArrowLeftEndOnRectangleIcon,
    iconBackground: 'bg-stone-400',
  },
]
</script>

<template>
  <HeadMetaTags
    :title="`${ship.name} Voyage`"
    :description="`A history of the ship ${ship.name} as it transported convicts to Australia.`"
    :canonical="route('ships.voyages.show', { ship, voyage })"
    :url="route('ships.voyages.show', { ship, voyage })"
  />

  <Container>
    <PageHeader
      class="whitespace-pre-line"
      :title="
        ship.name +
        ' Voyage \n ' +
        moment(voyage.departure_date).format('Do MMM YYYY') +
        ' - ' +
        (voyage.arrival_date ? moment(voyage.arrival_date).format('Do MMM YYYY') : 'Unknown')
      "
    >
      <template #actions>
        <PrimaryButton rel="nofollow" :href="route('voyages.edit', voyage)"> Edit</PrimaryButton>
      </template>
    </PageHeader>

    <article class="rounded-b-lg bg-white p-4 sm:px-8">
      <section class="min-w-md mt-8 flex items-start justify-center space-x-4 px-4">
        <img :src="ship_image(ship.image)" :alt="ship.name" class="size-24 rounded-sm sm:mr-4" />

        <div
          v-if="ship.description"
          class="prose hidden w-3/5 whitespace-pre-line md:flex lg:w-2/5"
        >
          {{ ship.description }}
        </div>

        <div class="flow-root">
          <ul role="list" class="-mb-8">
            <li v-for="(event, index) in timeline" :key="index">
              <div class="relative pb-8">
                <span
                  v-if="index !== timeline.length - 1"
                  class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      :class="[
                        event.iconBackground,
                        'flex size-8 items-center justify-center rounded-full',
                      ]"
                    >
                      <component :is="event.icon" class="size-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p class="text-sm text-gray-500">
                        {{ event.label }}
                      </p>
                    </div>
                    <div class="whitespace-nowrap text-right text-sm text-gray-500">
                      <time v-if="event.datetime" :datetime="event.datetime">
                        {{ event.content }}
                      </time>
                      <span v-else>{{ event.content }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="prose w-full whitespace-pre-line break-words px-5 pt-3 md:hidden">
        {{ ship.description }}
      </section>

      <section class="space-y-4 pt-1">
        <SubSection :title="parseInt(convicts.total).toLocaleString() + ' Convicts Onboard'">
          <ConvictsTable :convicts="convicts" />
        </SubSection>

        <SubSection title="Other Voyages">
          <VoyagesTable
            :voyages="props.voyages"
            :searchable="false"
            :sortable="false"
            hide-paginator
          />
        </SubSection>
      </section>
    </article>
  </Container>
</template>
