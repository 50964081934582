<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: String,
  as: {
    type: String,
    default: 'h2',
  },
})

const titleClass = computed(() => {
  if (props.as === 'h2') {
    return 'py-1 text-xl font-semibold md:text-2xl'
  } else if (props.as === 'h1') {
    return ' text-2xl font-bold md:text-3xl'
  }

  return ''
})
</script>

<template>
  <div class="space-y-4">
    <div
      class="flex flex-wrap items-center justify-between space-y-2 border-b border-zinc-200 pb-2"
    >
      <component
        :is="as"
        :id="title"
        class="font-serif leading-6 text-stone-600"
        :class="[titleClass]"
        aria-label="Heading"
      >
        {{ title }}
      </component>

      <slot name="actions" />
    </div>

    <section>
      <slot />
    </section>
  </div>
</template>
