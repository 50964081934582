<script setup>
import { onMounted, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  collection: {
    type: Object,
    required: true,
  },
  filter: {
    type: String,
    required: true,
  },
})

const userName = ref('')

const hasInitialSearch = ref(false)

const buildURL = () => {
  const url = new URL(props.collection.first_page_url)
  const searchParam = new URLSearchParams(url.search)

  if (userName.value === '') {
    searchParam.delete('filter[' + props.filter + ']')
  } else {
    searchParam.set('filter[' + props.filter + ']', userName.value)
  }

  return url.origin + url.pathname + '?' + searchParam.toString()
}
const applyFilter = () => {
  router.get(buildURL())
}

const clearSearch = () => {
  userName.value = ''
  if (hasInitialSearch.value === false) return
  router.get(buildURL())
}

const initialiseSearchValue = () => {
  const searchParam = new URLSearchParams(window.location.search)
  const filterValue = searchParam.get('filter[' + props.filter + ']')

  if (filterValue) {
    userName.value = filterValue
    hasInitialSearch.value = true
  }
}

onMounted(() => {
  initialiseSearchValue()
})
</script>

<template>
  <div class="mt-2 flex rounded-md shadow-sm">
    <div class="relative flex grow items-stretch focus-within:z-10">
      <label
        for="name"
        class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Convict Name
      </label>
      <input
        id="name"
        v-model="userName"
        type="text"
        name="name"
        class="block w-full rounded-l-md border-0 py-1.5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Search"
      />
      <div
        class="absolute inset-y-0 right-1 flex cursor-pointer items-center"
        @click="clearSearch()"
      >
        <XMarkIcon class="size-4 text-gray-400" aria-hidden="true" />
      </div>
    </div>
    <button
      type="button"
      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      @click="applyFilter"
    >
      Search
    </button>
  </div>
</template>
