<script setup>
import { Container } from '@codinglabsau/ui'

import Table from '@/Components/Convicts/Table.vue'
import IndexHeader from '@/Components/Convicts/IndexHeader.vue'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'

const props = defineProps({
  convicts: Object,
})
</script>

<template>
  <HeadMetaTags
    title="Convicts"
    description="A Index of all of our recorded convicts."
    :canonical="route('convicts.index')"
    :url="route('convicts.index')"
  />
  <Container>
    <IndexHeader />
    <div class="rounded-b-lg bg-white p-3 pt-4">
      <Table :convicts="props.convicts" />
    </div>
  </Container>
</template>
